import {
    Alert,
    AlertTitle,
    Snackbar,
    SnackbarCloseReason,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { StateStorage } from "../../../models/StateStorage";
import { setIsSettlementSupplyCreated } from "../../../store/slices/supply.slice";

export const SnackBar = () => {
    const dispatch = useDispatch<AppDispatch>();

    const { isSettlementSupplyCreated } = useSelector(
        (state: StateStorage) => state.supplyState,
    );

    const handleClose = (
        _event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(setIsSettlementSupplyCreated(false));
    };

    return (
        <Snackbar
            open={isSettlementSupplyCreated}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert severity="success">
                <AlertTitle>¡Archivo enviado con éxito!</AlertTitle>
                Este proceso puede tardar un poco. Por favor, verifica que tu
                archivo se haya cargado correctamente dentro unos minutos.
            </Alert>
        </Snackbar>
    );
};
