import { initEnviroment, onBeforeUnload } from "@enerbit/base";
import "./assets/css/makita.scss";
import Routers from "./router/Routers";

onBeforeUnload();

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_GEOPOLITICS_BASE_URL,
});

export default function Root() {
    return <Routers />;
}
