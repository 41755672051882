import {
  Box,
  CardPdf,
  FilterTiltShiftIcon,
  Grid,
  InfoIcon,
  Typography,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import { useSelector } from "react-redux";

import { StateStorage } from "../../models/StateStorage";
import { MonthlyFixedChargeTable } from "../../utils/MonthlyFixedChargeTable";

const NamePlan = () => {
  const { enerbitServiceAgreement } = useSelector(
    (state: StateStorage) => state.makitaState
  );

  const getIsFixed = () => {
    return enerbitServiceAgreement?.conditions.charges.find(
      (e) => e.name == "variable"
    )?.conditions.intervals?.length! > 1
      ? false
      : true;
  };
  const arrayExternal = [
    {
      name: "fixed",
      conditions: {
        price: 59500,
        components: [
          {
            name: "Alquiler de medidor",
            price: 50000,
          },
          {
            name: "Herramientas tecnológicas",
            price: 9900,
          },
        ],
      },
    },
    {
      name: "variable",
      conditions: {
        intervals: [
          {
            start: "00:00:00",
            duration: "24H",
            price: 310,
          },
        ],
      },
    },
  ];

  return (
    <>
      <Box className="Detail-plan-box">
        <Typography
          className="Title-name-plan"
          color={enerbitColors.primary.main}
        >
          {enerbitServiceAgreement?.name}
        </Typography>
        {/* <Box sx={{ background: enerbitColors.success[100] }} className="Box-status-plan">
          <Typography color={enerbitColors.success.main}>
            Activo
          </Typography>
        </Box> */}
        <Box sx={{ mb: "32px", mt: "20px" }}>
          <Typography color={enerbitColors.primary.main} fontWeight={700}>
            Descripción:
          </Typography>
          <Typography color={enerbitColors.neutral.main} lineHeight={"17px"}>
            {enerbitServiceAgreement?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {MonthlyFixedChargeTable(arrayExternal)}
          {/* {arrayExternal.map((obj: { conditions: { price: number; components: any[]; intervals: any[] } }) => {
          if (obj.conditions && obj.conditions.components) {
            return obj.conditions.components.map((component) => {
              console.log(`conditions ${obj.conditions.price}`);
              return (
                <>
                 
                </>
              );
            });
          }

        })} */}
        </Box>

        <Box className="Title-description-iva">
          <Box>
            <InfoIcon sx={{ color: "var(--color-error500)", mr: "12px" }} />
          </Box>
          <Box>
            <Typography
              sx={{ color: "var(--color-error500)" }}
              fontSize={"16px"}
            >
              Este plan no cuenta con Herramientas Tecnológicas y no contará con
              múltiples funcionalidades en la App
            </Typography>
          </Box>
        </Box>

        {/* <Grid container spacing={2} sx={{ mb: "32px" }}>
          <Grid item xs={6}>
            <Box sx={{ color: enerbitColors.primary.main }}>
              <Typography fontWeight={700}>Valor fijo antes de IVA:</Typography>
              <Typography className="Text-price-iva">
                {formatterPeso.format(
                  enerbitServiceAgreement?.conditions.charges.find((e) => e.name == "fixed")?.conditions.price || 0
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ color: enerbitColors.primary.main }}>
              <Typography fontWeight={700}>Valor fijo incluyendo IVA:</Typography>
              <Typography className="Text-price-iva">
                {formatterPeso.format(
                  (enerbitServiceAgreement?.conditions.charges.find((e) => e.name == "fixed")?.conditions.price || 0) *
                    1.19
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} sx={{ mb: "32px" }}>
          {getIsFixed() && (
            <Grid item xs={6}>
              <Box sx={{ color: enerbitColors.primary.main }}>
                <Typography fontWeight={700} color={enerbitColors.primary.main}>
                  Valor de la cobertura:
                </Typography>
                <Typography
                  className="Text-price-iva"
                  color={enerbitColors.primary.main}
                >
                  {formatterPeso.format(
                    enerbitServiceAgreement?.conditions.charges.find(
                      (e) => e.name == "variable"
                    )?.conditions.intervals![0].price || 0
                  )}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography color={enerbitColors.primary.main} fontWeight={700}>
              Tipo de cobertura:
            </Typography>
            <Box
              sx={{ background: enerbitColors.neutral[100] }}
              className="Box-status-plan-disabled"
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color={enerbitColors.neutral.main}
              >
                {getIsFixed() ? (
                  <FilterTiltShiftIcon sx={{ fontSize: "13px", mr: "6px" }} />
                ) : (
                  <FilterTiltShiftIcon sx={{ fontSize: "13px", mr: "6px" }} />
                )}
                {getIsFixed() ? "Fijo" : "Variable"}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {!getIsFixed() && (
          <Grid container spacing={2} sx={{ mb: "10px" }}>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Hora inicial
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Hora final
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Valor
              </Box>
            </Grid>
          </Grid>
        )}
        {!getIsFixed() &&
          enerbitServiceAgreement?.conditions.charges
            .find((e) => e.name == "variable")
            ?.conditions.intervals!.map((r, index) => (
              <Grid container spacing={2} sx={{ mb: "10px" }} key={index}>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {r.start}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {r.start + r.duration}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {r.price}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
        <Box sx={{ mt: "30px" }}>
          <CardPdf
            typeFile=".md"
            isSuccess={true}
            downloadUrl={enerbitServiceAgreement?.path_file}
            bgColor="success"
            fileInfo={{
              name:
                enerbitServiceAgreement?.name ??
                "Nombre de archivo no encontrado",
              size: 12,
            }}
            onClearFile={() => {}}
            progress={0}
          />
        </Box>
      </Box>
    </>
  );
};

export default NamePlan;
