import {
    Box,
    Button,
    Grid,
    InfoIcon,
    InputLabel,
    LoadingButton,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyService } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { AppDispatch } from "../../store/store";
import { CardEnerbitServiceAgreement } from "../reusable/CardEnerbitServiceAgreement";

export type FormChangeModalProps = {
    handleClose?: (
        event: {},
        reason: "backdropClick" | "escapeKeyDown",
    ) => void;
    handleChangePage: () => void;
};

export const FormLastModal = ({
    handleClose,
    handleChangePage,
}: FormChangeModalProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        enerbitServiceAgreementPlan,
        enerbitServiceAgreement,
        isLoadingUpdateChangePlan,
        errorUpdateChangePlan,
        successUpdateChangePlan,
    } = useSelector((state: StateStorage) => state.makitaState);

    useEffect(() => {
        dispatch(getEnerbitElectricitySupplyService());
    }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <InputLabel
                shrink
                className="Input-label"
                sx={{ fontSize: "18px" }}
            >
                Todos los servicios del plan:
            </InputLabel>
            {enerbitServiceAgreement && (
                <Box>
                    <CardEnerbitServiceAgreement
                        isChangePlan={true}
                        enerbitServiceAgreement={enerbitServiceAgreement}
                    />
                </Box>
            )}
            <InputLabel
                shrink
                className="Input-label"
                sx={{ fontSize: "18px", marginTop: "24px" }}
            >
                Se cambiarán al plan:
            </InputLabel>
            {enerbitServiceAgreementPlan && (
                <Box>
                    <CardEnerbitServiceAgreement
                        isChangePlan={true}
                        enerbitServiceAgreement={enerbitServiceAgreementPlan}
                    />
                </Box>
            )}
            <Box
                className="Box-custom-alert-modal"
                sx={{ backgroundColor: enerbitColors.warning[100] }}
            >
                <InfoIcon sx={{ color: enerbitColors.warning.main }}></InfoIcon>
                <Typography
                    color={enerbitColors.neutral[900]}
                    fontWeight={900}
                    sx={{ ml: "10px" }}
                >
                    Esta acción NO se puede deshacer
                </Typography>
            </Box>
            <Grid container spacing={4} sx={{ marginTop: "0px" }}>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            if (handleClose) {
                                handleClose({}, "backdropClick");
                            }
                        }}
                    >
                        Cancelar
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="secondary"
                        loading={isLoadingUpdateChangePlan}
                        onClick={handleChangePage}
                    >
                        Continuar
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
};
