import { Box, Chip, CircularProgress, enerbitColors } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import { getSettlementSupplyStateById } from "../../../store/actions/supplies/supplies";
import { AppDispatch } from "../../../store/store";

interface SupplyStateProps {
    backgroundColor?: string;
    textColor?: string;
}

export const SupplyState = ({ supplyStateId }: { supplyStateId: string }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { isLoadingSettlementSupplyStateById, settlementSupplyState } =
        useSelector((state: StateStorage) => state.supplyState);

    useEffect(() => {
        dispatch(getSettlementSupplyStateById(supplyStateId));
    }, []);

    function getColorForSettlementSupplyState(state: string): SupplyStateProps {
        let backgroundColor: string;
        let textColor: string;
        switch (state) {
            case "Empty":
                backgroundColor = "grey";
                textColor = enerbitColors.neutral[700] ?? "";
                break;
            case "Blob Uploaded":
                backgroundColor = enerbitColors.primary[200] ?? "";
                textColor = enerbitColors.primary.main ?? "";
                break;
            case "Sended":
                backgroundColor = enerbitColors.warning[100] ?? "";
                textColor = enerbitColors.warning[600] ?? "";
                break;
            case "Processing":
                backgroundColor = enerbitColors.warning[100] ?? "";
                textColor = enerbitColors.warning[600] ?? "";
                break;
            case "Failed":
                backgroundColor = enerbitColors.error[200] ?? "";
                textColor = enerbitColors.error[600] ?? "";
                break;
            case "Creating":
                backgroundColor = enerbitColors.information[200] ?? "";
                textColor = enerbitColors.information[600] ?? ""; // Cambiando el color del texto a blanco para un fondo rojo
                break;
            case "Uploaded":
                backgroundColor = enerbitColors.secondary_green[200] ?? "";
                textColor = enerbitColors.secondary_green[600] ?? ""; // Cambiando el color del texto a blanco para un fondo rojo
                break;
            default:
                backgroundColor = "white";
                textColor = "black";
                break;
        }

        return { backgroundColor, textColor };
    }

    const { backgroundColor, textColor } = getColorForSettlementSupplyState(
        settlementSupplyState.state || "DefaultState",
    );

    return (
        <Box>
            {isLoadingSettlementSupplyStateById ? (
                <Box className="Loading-flex" sx={{ margin: 0 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Chip
                    label={settlementSupplyState.description}
                    style={{
                        color: textColor,
                        backgroundColor: backgroundColor,
                    }}
                />
            )}
        </Box>
    );
};
