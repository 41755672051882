import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    CardPdf,
    Checkbox,
    CustomAlert,
    CustomModal,
    DragZone,
    Grid,
    InputLabel,
    LoadingButton,
    TextField,
    Typography,
    useFormik,
    yup,
} from "@enerbit/base";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import { getLabelServiceAgreementType } from "../../../helpers/GetLabelServiceAgreementType";
import {
    EnerbitServiceAgreementPlanCreate,
    ServiceAgreementType,
} from "../../../models/Makita";
import { CustomModalTypes } from "../../../models/ModalProps";
import { StateStorage } from "../../../models/StateStorage";
import { postEnerbitElectricitySupplyServiceAgreements } from "../../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { setResetVarsCreatePlans } from "../../../store/slices/makitaSlice";
import { AppDispatch } from "../../../store/store";
import { errorInputFields } from "../../../utils/ErrorInputFields";

const CreateModalPlan = forwardRef((props, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const createClient = useRef<CustomModalTypes>(null);
    const [isCheckedTechnologicalTools, setIsCheckedTechnologicalTools] =
        useState(true);
    const { errorCreatePlansEnergy, successCreatePlansEnergy } = useSelector(
        (state: StateStorage) => state.makitaState,
    );

    const handleCheckboxTechnologicalTools = () => {
        setIsCheckedTechnologicalTools(!isCheckedTechnologicalTools);
    };

    useImperativeHandle(ref, () => ({
        changeModal() {
            if (createClient.current) {
                createClient.current.changeModal();
            }
        },
    }));

    const initialValues: EnerbitServiceAgreementPlanCreate = {
        name: "",
        description: "",
        service_agreement_type: ServiceAgreementType.fijabit,
        priceBeforeVat: 0,
        priceCoverage: 0,
        technologicalToolsBeforeIva: 0,
        intervals: [
            {
                start: "00:00:00",
                duration: "24H",
                price: 0,
            },
        ],
        agreement_file: null,
    };

    const validationSchema = yup.object({
        checkedTypeCoverage: yup.boolean(),
        labels: yup.string(),
        name: yup.string().required(errorInputFields.inputRequired),
        description: yup.string().required(errorInputFields.inputRequired),
        priceBeforeVat: yup
            .number()
            .integer(errorInputFields.inputWholeNumbersRequired)
            .required(errorInputFields.inputRequired)
            .min(0, errorInputFields.inputGreaterThanZeroRequired)
            .typeError(errorInputFields.inputNumberRequired),
        technologicalToolsBeforeIva: yup
            .number()
            .integer(errorInputFields.inputWholeNumbersRequired)
            .required(errorInputFields.inputRequired)
            .min(1, errorInputFields.inputGreaterThanOrEqualZeroRequired)
            .typeError(errorInputFields.inputNumberRequired),
        priceCoverage: yup
            .number()
            .integer(errorInputFields.inputWholeNumbersRequired)
            .when("service_agreement_type", {
                is: (valServiceAgreementType: ServiceAgreementType) => {
                    return (
                        valServiceAgreementType === ServiceAgreementType.fijabit
                    );
                },
                then: (schema) =>
                    schema
                        .required(errorInputFields.inputRequired)
                        .min(1, errorInputFields.inputGreaterThanZeroRequired)
                        .typeError(errorInputFields.inputNumberRequired),
                otherwise: (schema) => schema,
            }),
        //TODO: Se trabajara la validacion de intervals cuando se habilite la creacion de cobertura variable
        /* intervals: yup
      .array()
      .of(
        yup.object().shape({
          start: yup.number().typeError("El valor debe ser tipo númerico"),
          duration: yup.number().typeError("El valor debe ser tipo númerico"),
          price: yup.number().typeError("El valor debe ser tipo númerico"),
        })
      )
      .when("service_agreement_type", {
        is: (valServiceAgreementType: ServiceAgreementType) => {
          return valServiceAgreementType === ServiceAgreementType.dinabit;
        },
        then: (schema) =>
          schema.of(
            yup.object().shape({
              start: yup
                .number()
                .required("El campo es requerido")
                .typeError("El valor debe ser tipo númerico")
                .test("start", "El campo debe estar entre 0 y 23", (value, context) => {
                  if (value === undefined || value === null) {
                    return false;
                  }
                  return value >= 0 && value <= 23;
                }),
              duration: yup
                .number()
                .required("El campo es requerido")
                .typeError("El valor debe ser tipo númerico")
                .test("duration", "El campo debe ser desde 1 hasta 24", (value, context) => {
                  if (value === undefined || value === null) {
                    return false;
                  }
                  return value >= 1 && value <= 24;
                }),
              price: yup
                .number()
                .required("El campo es requerido")
                .test("price", "El campo debe ser mayor a 0", (value, context) => {
                  if (value === undefined || value === null) {
                    return false;
                  }
                  return value > 0;
                }),
            })
          ),
        otherwise: (schema) => schema,
      }), */
        agreement_file: yup.mixed().required(errorInputFields.inputRequired),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: () => {
            dispatch(
                postEnerbitElectricitySupplyServiceAgreements(formik.values),
            ).then((e) => {
                if (createClient.current) {
                    createClient.current.changeModal();
                    formik.resetForm();
                }
            });
        },
    });

    const filtersSelected = [
        {
            value: ServiceAgreementType.fijabit,
            icon: <FilterTiltShiftIcon />,
            component: <></>,
        } /* ,
    {
      value: ServiceAgreementType.dinabit,
      icon: <RotateRightIcon />,
      component: (
        <>
          <Box sx={{ display: "flex", paddingLeft: "1rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TitleCreationCoverage title={"Hora inicial"} />
              </Grid>
              <Grid item xs={4}>
                <TitleCreationCoverage title={"Hora Final"} />
              </Grid>
              <Grid item xs={4}>
                <TitleCreationCoverage title={"Valor"} />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "2rem",
                height: "2rem",
                margin: "auto 0 auto 1rem",
              }}
            ></Box>
          </Box>

          <FormikProvider value={formik}>
            <FieldArray
              name="intervals"
              validateOnChange={true}
              render={(arrayHelpers) => (
                <div>
                  {formik.values.intervals.map((intervals, index: number): JSX.Element => {
                    return (
                      <Box key={index} mt={2} display="flex" alignItems="center" paddingLeft="1rem">
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              id={`intervals[${index}].start`}
                              name={`intervals[${index}].start`}
                              variant="outlined"
                              value={formik.values.intervals?.[index].start}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.intervals?.[index]?.start &&
                                //@ts-ignore // TODO: Quitar los ts ignore despues
                                Boolean(formik.errors.intervals?.[index]?.start)
                              }
                              helperText={
                                formik.touched.intervals?.[index]?.start &&
                                //@ts-ignore // TODO: Quitar los ts ignore despues
                                formik.errors.intervals?.[index]?.start
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              id={`intervals.[${index}].duration`}
                              name={`intervals.[${index}].duration`}
                              variant="outlined"
                              value={formik.values.intervals?.[index].duration}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.intervals?.[index]?.duration &&
                                //@ts-ignore // TODO: Quitar los ts ignore despues
                                Boolean(formik.errors.intervals?.[index]?.duration)
                              }
                              helperText={
                                formik.touched.intervals?.[index]?.duration &&
                                //@ts-ignore // TODO: Quitar los ts ignore despues
                                formik.errors.intervals?.[index]?.duration
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumericFormat
                              className="Input-variable-value"
                              id={`intervals[${index}].price`}
                              name={`intervals[${index}].price`}
                              value={formik.values.intervals[index].price}
                              customInput={TextField}
                              onBlur={formik.handleBlur}
                              onValueChange={(values) => {
                                formik.setFieldValue(`intervals.[${index}].price`, values.floatValue ?? "");
                              }}
                              error={
                                formik.touched.intervals?.[index]?.price &&
                                //@ts-ignore // TODO: Quitar los ts ignore despues
                                Boolean(formik.errors.intervals?.[index]?.price)
                              }
                              valueIsNumericString
                              thousandSeparator
                              prefix="$"
                            />
                            <ErrorMessage
                              name={`intervals[${index}].price`}
                              render={(msg) => <Typography className="Text-validation-variable">{msg}</Typography>}
                            />
                          </Grid>
                        </Grid>
                        <Box
                          width="2rem"
                          height="2rem"
                          sx={{
                            margin: "auto 0 auto 1rem",
                          }}
                        >
                          <Button
                            id="button-delete-interval"
                            name="buttonDeleteInterval"
                            color="error"
                            disabled={!validateIntervalsMinimum()}
                            onClick={(e) => {
                              arrayHelpers.remove(index);
                            }}
                            className="Loading-button Button-delete-interval"
                          >
                            <Box className="Container-delete-plan">
                              <DeleteOutlineIcon />
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    );
                  })}
                  <Box className="Modal-buttons-container" sx={{ paddingLeft: "1rem" }}>
                    <Button
                      fullWidth
                      sx={{
                        marginTop: "20px",
                        paddingLeft: "1rem",
                      }}
                      variant="outlined"
                      disabled={!validateIntervals()}
                      color="primary"
                      startIcon={<AddIcon className="Icon-item-add-interval" />}
                      onClick={(e) => {
                        const intervals = {
                          start: "",
                          duration: "",
                          price: 0,
                        };
                        arrayHelpers.push(intervals);
                      }}
                    >
                      Agregar intervalo
                    </Button>
                  </Box>
                </div>
              )}
            />
          </FormikProvider>
        </>
      ),
    }, */,
    ];

    const onDrop = useCallback((acceptedFiles: Blob[]): void => {
        formik.setFieldValue("agreement_file", acceptedFiles[0]);
    }, []);

    return (
        <CustomModal
            maxWidth="md"
            ref={createClient}
            onClose={() => {
                dispatch(setResetVarsCreatePlans());
                formik.resetForm();
            }}
            dialogContent={
                <form onSubmit={formik.handleSubmit}>
                    <Box className="Header-plan" sx={{ fontWeight: "600" }}>
                        Creación de un plan
                    </Box>{" "}
                    <InputLabel shrink className="Input-label">
                        Nombre
                    </InputLabel>{" "}
                    <Box className="TextField-without-border-radius Text-field-filters">
                        <TextField
                            id="Input-modal-name-creation-plan"
                            name="name"
                            placeholder="Nombre del plan"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: "100%", borderRadius: "14px" }}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                    </Box>
                    <InputLabel shrink className="Input-label">
                        Descripción
                    </InputLabel>
                    <Box className="TextField-without-border-radius Text-field-filters">
                        <TextField
                            id="Input-modal-description-creation-plan"
                            name="description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Aquí va la descripción"
                            sx={{ width: "100%", borderRadius: "14px" }}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                        />
                    </Box>
                    <InputLabel shrink className="Input-label">
                        Alquiler del medidor antes de IVA
                    </InputLabel>
                    <Box className="TextField-without-border-radius Text-field-filters">
                        <TextField
                            id="Input-modal-fixed-value-creation-plan"
                            name="priceBeforeVat"
                            placeholder="Valor númerico"
                            sx={{ width: "100%", borderRadius: "14px" }}
                            value={formik.values.priceBeforeVat}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.priceBeforeVat &&
                                Boolean(formik.errors.priceBeforeVat)
                            }
                            helperText={
                                formik.touched.priceBeforeVat &&
                                formik.errors.priceBeforeVat
                            }
                        />
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        sx={{ padding: "0.2rem 0 0 0" }}
                    >
                        <Grid
                            item
                            xs={6}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Checkbox
                                checked={isCheckedTechnologicalTools}
                                onChange={handleCheckboxTechnologicalTools}
                                className="Checkbox-makita"
                            />
                            <InputLabel
                                shrink
                                className="Input-label Input-label-technological-tools"
                            >
                                Aplicabilidad de herramientas tecnológicas
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <InputLabel
                                shrink
                                className="Input-label"
                                sx={{
                                    color: isCheckedTechnologicalTools
                                        ? "inherit"
                                        : "rgba(0, 0, 0, 0.6) !important", // Cambia el color del texto dependiendo del estado del checkbox
                                }}
                                disabled={!isCheckedTechnologicalTools}
                            >
                                Herramientas tecnológicas antes de IVA
                            </InputLabel>
                            <Box className="TextField-without-border-radius Text-field-filters">
                                <TextField
                                    disabled={!isCheckedTechnologicalTools}
                                    id="Input-modal-fixed-value-creation-plan"
                                    placeholder="Valor númerico"
                                    name="technologicalToolsBeforeIva"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: "100%", borderRadius: "14px" }}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched
                                            .technologicalToolsBeforeIva &&
                                        Boolean(
                                            formik.errors
                                                .technologicalToolsBeforeIva,
                                        )
                                    }
                                    helperText={
                                        formik.touched
                                            .technologicalToolsBeforeIva &&
                                        formik.errors
                                            .technologicalToolsBeforeIva
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box>
                        {/* //TODO: Agregar los estilos de esta clase al base */}
                        <Typography className="Title-type-coverage">
                            ¿Qué tipo de cobertura tiene este plan?
                        </Typography>
                        <Box>
                            <Grid container spacing={2}>
                                {filtersSelected.map((value, index) => (
                                    <Grid item xs={12} md={12} key={index}>
                                        <Box
                                            id={
                                                value.value ===
                                                formik.values
                                                    .service_agreement_type
                                                    ? "Select-modal-fixed-coverage"
                                                    : "Select-modal-variable-coverage"
                                            }
                                            className={
                                                value.value ===
                                                formik.values
                                                    .service_agreement_type
                                                    ? "Card-coverage selected"
                                                    : "Card-coverage"
                                            }
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    "service_agreement_type",
                                                    value.value,
                                                );
                                            }}
                                        >
                                            <Box className="Icons-select-services">
                                                <Box
                                                    sx={{
                                                        marginRight: "3.5rem",
                                                    }}
                                                    className="Icon-makita-plan"
                                                >
                                                    {value.icon}
                                                </Box>
                                                <Box className="Label-search-filter">
                                                    {getLabelServiceAgreementType(
                                                        value.value,
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}

                                {filtersSelected.map((value, index) => (
                                    <Box
                                        sx={{
                                            display:
                                                value.value ===
                                                formik.values
                                                    .service_agreement_type
                                                    ? "block"
                                                    : "none",
                                            width: "100%",
                                        }}
                                        key={index}
                                    >
                                        {" "}
                                        {value.component}{" "}
                                    </Box>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                    {formik.values.service_agreement_type ===
                    ServiceAgreementType.fijabit ? (
                        <>
                            <InputLabel shrink className="Input-label">
                                Valor de la cobertura
                            </InputLabel>
                            <Box className="TextField-without-border-radius Text-field-filters">
                                <TextField
                                    id="Input-modal-coverage-value-creation-plan"
                                    name="priceCoverage"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ width: "100%", borderRadius: "14px" }}
                                    placeholder="Valor númerico"
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.priceCoverage &&
                                        Boolean(formik.errors.priceCoverage)
                                    }
                                    helperText={
                                        formik.touched.priceCoverage &&
                                        formik.errors.priceCoverage
                                    }
                                />
                            </Box>
                        </>
                    ) : (
                        <Box></Box>
                    )}
                    {
                        <>
                            <Box>
                                {formik.values.agreement_file ? (
                                    <>
                                        <CardPdf
                                            // @ts-ignore //TODO quitar este ts ignore despues
                                            id="Input-modal-agreement_file-value-creation-plan"
                                            name="agreement_file"
                                            typeFile=".md"
                                            isSuccess={true}
                                            bgColor="success"
                                            isClearFile={true}
                                            color="error"
                                            fileInfo={
                                                formik.values.agreement_file
                                            }
                                            onClearFile={() =>
                                                formik.setFieldValue(
                                                    "agreement_file",
                                                    null,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <DragZone
                                        // @ts-ignore //TODO quitar este ts ignore despues
                                        onDrop={onDrop}
                                        color="success"
                                        typeFile="md"
                                        // @ts-ignore //TODO quitar este ts ignore despues
                                        error={
                                            formik.touched.agreement_file &&
                                            formik.errors.agreement_file
                                        }
                                    />
                                )}
                                <Typography sx={{ color: "red" }}>
                                    {formik.touched.agreement_file &&
                                        formik.errors.agreement_file}
                                </Typography>
                            </Box>
                        </>
                    }
                    <Box className="Modal-buttons-container">
                        <Button
                            id="Button-modal-cancel"
                            name="buttonModalCancel"
                            color="warning"
                            size="small"
                            className="Modal-button-cancel"
                            onClick={() => {
                                createClient.current?.changeModal();
                            }}
                            sx={{ width: "49%", height: "2.5625rem" }}
                        >
                            <Box style={{ fontWeight: "bold", margin: 0 }}>
                                Cancelar
                            </Box>
                        </Button>

                        <LoadingButton
                            id="Button-modal-save"
                            type="submit"
                            name="buttonModalSave"
                            color="warning"
                            size="small"
                            className={
                                successCreatePlansEnergy
                                    ? `Loading-button Modal-button-logout button-disabled`
                                    : `Loading-button Modal-button-logout`
                            }
                            disabled={successCreatePlansEnergy ? true : false}
                            sx={{ width: "49%", height: "2.5625rem" }}
                        >
                            <Typography
                                style={{
                                    fontWeight: "bold",
                                    margin: 0,
                                    color: "white",
                                }}
                            >
                                Guardar
                            </Typography>
                        </LoadingButton>
                    </Box>
                    {errorCreatePlansEnergy != "" && (
                        <CustomAlert
                            onClose={() => {}}
                            sx={{ width: "100%", mt: "1rem" }}
                            severity="error"
                            text={errorCreatePlansEnergy}
                        />
                    )}
                    {successCreatePlansEnergy != "" && (
                        <CustomAlert
                            onClose={() => {}}
                            sx={{ width: "100%", mt: "1rem" }}
                            severity="success"
                            text={successCreatePlansEnergy}
                        />
                    )}
                </form>
            }
        />
    );
});

export default CreateModalPlan;
