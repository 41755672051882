import { Box, CustomAlert } from "@enerbit/base";

const CustomError = ({ message }: { message: string }) => {
  return (
    <Box sx={{ pl: "1rem" }}>
      <CustomAlert
        onClose={() => {}}
        sx={{ width: "100%", mt: "1rem" }}
        severity="error"
        text={message}
      />
    </Box>
  );
};

export default CustomError;
