import { enerbitColors, formatterPeso } from "@enerbit/base";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { calculateEndTimeFromDuration } from "../../helpers/IntervalPlanHelpers";
import {
  ChargeConditions,
  EnerbitServiceAgreement,
} from "../../models/plan-administration/PlanAdministration";
import { formatPriceToCOPWithoutDecimals } from "../../utils/formatPrice";

interface PlanCoverageDetailsProps {
  isVariablePlan: boolean;
  enerbitServiceAgreement: EnerbitServiceAgreement<ChargeConditions> | null;
}

const PlanCoverageDetails: React.FC<PlanCoverageDetailsProps> = ({
  isVariablePlan,
  enerbitServiceAgreement,
}) => {
  return (
    <>
      <Grid container spacing={2} sx={{ mb: "32px" }}>
        {!isVariablePlan && (
          <Grid item xs={6}>
            <Box sx={{ color: enerbitColors.primary.main }}>
              <Typography fontWeight={700} color={enerbitColors.primary.main}>
                Valor de la cobertura:
              </Typography>
              <Typography
                className="Text-price-iva"
                color={enerbitColors.primary.main}
              >
                {formatterPeso.format(
                  enerbitServiceAgreement?.conditions.charges.find(
                    (e) => e.name === "variable"
                  )?.conditions.intervals![0].price || 0
                )}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography color={enerbitColors.primary.main} fontWeight={700}>
            Tipo de cobertura:
          </Typography>
          <Box
            sx={{ background: enerbitColors.neutral[100] }}
            className="Box-status-plan-disabled"
          >
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color={enerbitColors.neutral.main}
            >
              {isVariablePlan ? (
                <FilterTiltShiftIcon sx={{ fontSize: "13px", mr: "6px" }} />
              ) : (
                <FilterTiltShiftIcon sx={{ fontSize: "13px", mr: "6px" }} />
              )}
              {isVariablePlan ? "Variable" : "Fijo"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {isVariablePlan && (
        <>
          <Grid container spacing={2} sx={{ mb: "10px" }}>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Hora de inicio
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Hora final
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{ color: enerbitColors.neutral[700] }}
                className="Title-plan-variable"
              >
                Valor
              </Box>
            </Grid>
          </Grid>
          {enerbitServiceAgreement?.conditions.charges
            .find((e: { name: string }) => e.name == "variable")
            ?.conditions.intervals!.map((r, index: number) => (
              <Grid container spacing={2} sx={{ mb: "10px" }} key={index}>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {r.start}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {calculateEndTimeFromDuration({
                        start: r.start,
                        duration: r.duration,
                      })}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="Rate-box">
                    <Typography color={enerbitColors.neutral[700]}>
                      {formatPriceToCOPWithoutDecimals.format(r.price)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </>
      )}
    </>
  );
};

export default PlanCoverageDetails;
