import {
    AssetOwnerShip,
    EnerbitPagination,
    Market,
    Retailer,
    SuiSocialStratum,
    TensionLevel,
} from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    CalculatorState,
    CreatePreOffert,
    ResponseTariff,
    RootPlans,
} from "../../models/Calculator";
import { EnerbitServiceAgreement } from "../../models/Makita";
import {
    getMarkets,
    getRetailers,
} from "../actions/electricity-market-info/electricity-market-info.actions";
import {
    createPreOffers,
    getAssetOwnership,
    getEnerbitElectricitySupplyServiceAgreements,
    getListPlans,
    getSuiSocialStratums,
    getTariffEnerbit,
    getTensionLevel,
} from "../actions/electricity-supply-service/electricity-supply-service.actions";

const initialState: CalculatorState = {
    isLoadingRetailers: true,
    isLoadingMarket: true,
    isLoadingAssetOwnership: true,
    isLoadingSuiSocialStratums: true,
    isLoadingEnerbitElectricitySupplyServiceAgreements: true,
    isLoadingTensionLevel: true,
    paginationAssetOwnership: [],
    paginationTensionLevel: [],
    createPreOffert: {},
    isLoadingCreateOffert: false,
    isLoadingTariffEnerbit: false,
    isLoadingListPlans: false,
    tariffEnerbit: [],
    listPlans: [],
    pageListPlans: 0,
    countListPlans: 0,
    totalListPlans: 0,
    successCreatePreOffert: "",
    errorCreatePreOffert: "",
};

export const calculatorState = createSlice({
    name: "calculatorState",
    initialState,
    reducers: {
        resetPreOffers: (state) => {
            state.createPreOffert = {};
        },
        resetCreateOffersAlerts: (state) => {
            state.successCreatePreOffert = "";
            state.errorCreatePreOffert = "";
        },
        handlePageChangeListPlans: (state, action: PayloadAction<number>) => {
            state.pageListPlans = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // getRetailers
            .addCase(getRetailers.pending.type, (state, _action) => {
                state.isLoadingRetailers = true;
                state.paginationRetailer = undefined;
            })
            .addCase(
                getRetailers.fulfilled.type,
                (state, action: PayloadAction<EnerbitPagination<Retailer>>) => {
                    state.isLoadingRetailers = false;
                    const items = action.payload.items.sort((a, b) => {
                        if (a.description < b.description) {
                            return -1;
                        }
                        if (a.description > b.description) {
                            return 1;
                        }
                        return 0;
                    });
                    state.paginationRetailer = { ...action.payload, items };
                },
            )
            .addCase(getRetailers.rejected.type, (state, _action) => {
                state.isLoadingRetailers = false;
            }) // getMarkets
            .addCase(getMarkets.pending.type, (state, _action) => {
                state.isLoadingMarket = true;
                state.paginationMarkets = undefined;
            })
            .addCase(
                getMarkets.fulfilled.type,
                (state, action: PayloadAction<EnerbitPagination<Market>>) => {
                    state.isLoadingMarket = false;
                    const items = action.payload.items.sort((a, b) => {
                        if (a.description < b.description) {
                            return -1;
                        }
                        if (a.description > b.description) {
                            return 1;
                        }
                        return 0;
                    });
                    state.paginationMarkets = { ...action.payload, items };
                },
            )
            .addCase(getMarkets.rejected.type, (state, _action) => {
                state.isLoadingMarket = false;
            })
            // getAssetOwnerShip
            .addCase(getAssetOwnership.pending.type, (state, _action) => {
                state.isLoadingAssetOwnership = true;
                state.paginationAssetOwnership = [];
            })
            .addCase(
                getAssetOwnership.fulfilled.type,
                (state, action: PayloadAction<AssetOwnerShip[]>) => {
                    state.isLoadingAssetOwnership = false;
                    state.paginationAssetOwnership = action.payload;
                },
            )
            .addCase(getAssetOwnership.rejected.type, (state, _action) => {
                state.isLoadingAssetOwnership = false;
            })
            // getSuiSocialStratums
            .addCase(getSuiSocialStratums.pending.type, (state, _action) => {
                state.suiSocialStratum = [];
                state.isLoadingSuiSocialStratums = true;
            })
            .addCase(
                getSuiSocialStratums.fulfilled.type,
                (state, action: PayloadAction<SuiSocialStratum[]>) => {
                    state.isLoadingSuiSocialStratums = false;
                    const items = action.payload.sort(
                        (a, b) => Number(a.sui_code) - Number(b.sui_code),
                    );
                    state.suiSocialStratum = items;
                },
            )
            .addCase(getSuiSocialStratums.rejected, (state, _action) => {
                state.isLoadingSuiSocialStratums = false;
            })
            // getEnerbitElectricitySupplyServiceAgreements
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.pending.type,
                (state, _action) => {
                    state.isLoadingEnerbitElectricitySupplyServiceAgreements = true;
                    state.paginationEnerbitElectricitySupplyServiceAgreements =
                        undefined;
                },
            )
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.fulfilled.type,
                (
                    state,
                    action: PayloadAction<
                        EnerbitPagination<EnerbitServiceAgreement>
                    >,
                ) => {
                    state.isLoadingEnerbitElectricitySupplyServiceAgreements = false;
                    state.paginationEnerbitElectricitySupplyServiceAgreements =
                        action.payload;
                },
            )
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.rejected.type,
                (state, _action) => {
                    state.isLoadingEnerbitElectricitySupplyServiceAgreements = false;
                },
            ) // getTensionLevel
            .addCase(getTensionLevel.pending.type, (state, _action) => {
                state.isLoadingTensionLevel = true;
                state.paginationTensionLevel = [];
            })
            .addCase(
                getTensionLevel.fulfilled.type,
                (state, action: PayloadAction<TensionLevel[]>) => {
                    state.isLoadingTensionLevel = false;
                    state.paginationTensionLevel = action.payload;
                },
            )
            .addCase(getTensionLevel.rejected.type, (state, _action) => {
                state.isLoadingTensionLevel = false;
            }) // createPreOffert
            .addCase(getListPlans.pending.type, (state, _action) => {
                state.isLoadingListPlans = true;
            })
            .addCase(
                getListPlans.fulfilled.type,
                (state, action: PayloadAction<RootPlans>) => {
                    state.isLoadingListPlans = false;
                    state.listPlans = action.payload.items;
                    state.pageListPlans = action.payload.page;
                    state.countListPlans = action.payload.pages;
                    state.totalListPlans = action.payload.total;
                },
            )
            .addCase(getListPlans.rejected.type, (state, _action) => {
                state.isLoadingListPlans = false;
            })
            .addCase(createPreOffers.pending.type, (state, _action) => {
                state.isLoadingCreateOffert = true;
            })
            .addCase(
                createPreOffers.fulfilled.type,
                (state, action: PayloadAction<CreatePreOffert>) => {
                    state.isLoadingCreateOffert = false;
                    state.createPreOffert = action.payload;
                    state.successCreatePreOffert =
                        "Pre-oferta creada exitosamente";
                    state.errorCreatePreOffert = "";
                },
            )
            .addCase(createPreOffers.rejected.type, (state, _action) => {
                state.isLoadingCreateOffert = false;
                state.successCreatePreOffert = "";
                state.errorCreatePreOffert = "Ocurrió un error";
            })
            .addCase(getTariffEnerbit.pending.type, (state, _action) => {
                state.isLoadingTariffEnerbit = true;
            })
            .addCase(
                getTariffEnerbit.fulfilled.type,
                (state, action: PayloadAction<ResponseTariff[]>) => {
                    state.isLoadingTariffEnerbit = false;
                    state.tariffEnerbit = action.payload;
                },
            )
            .addCase(getTariffEnerbit.rejected.type, (state, _action) => {
                state.isLoadingTariffEnerbit = false;
            });
    },
});

export const {
    resetPreOffers,
    resetCreateOffersAlerts,
    handlePageChangeListPlans,
} = calculatorState.actions;

export default calculatorState.reducer;
