import { EnerbitPagination } from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
    CreatePlansEnergyErrors,
    EnerbitServiceAgreement,
    EnerbitServiceAgreementPlanCreate,
    MakitaState,
    UpdateChangePlanEnum,
} from "../../models/Makita";
import { StateStorage } from "../../models/StateStorage";
import {
    getEnerbitElectricitySupplyService,
    getEnerbitElectricitySupplyServiceAgreements,
    postEnerbitElectricitySupplyServiceAgreements,
    updateChangePlan,
} from "../actions/electricity-supply-service/electricity-supply-service.actions";

const initialState: MakitaState = {
    planList: undefined,
    isLoadingCreatePlansEnergy: false,
    isLoadingListPlansEnergy: false,
    successCreatePlansEnergy: "",
    errorCreatePlansEnergy: "",
    page: 0,
    pages: 0,
    size: 10,
    next_page: 0,
    enerbitServiceAgreement: null,
    isLoadingEnerbitServiceAgreements: false,
    paginationEnerbitServiceAgreements: undefined,
    enerbitServiceAgreementPlan: null,
    isLoadingUpdateChangePlan: false,
    successUpdateChangePlan: "",
    errorUpdateChangePlan: "",
    startedAt: moment().startOf("month").toDate().toString(),
};

export const makitaSlice = createSlice({
    name: "makitaState",
    initialState,
    reducers: {
        handlePageChange: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setResetVarsCreatePlans: (state) => {
            state.errorCreatePlansEnergy = "";
            state.successCreatePlansEnergy = "";
        },
        setResetVarsUpdatePlans: (state) => {
            state.isLoadingUpdateChangePlan = false;
            state.errorUpdateChangePlan = "";
            state.successUpdateChangePlan = "";
        },
        setEnerbitServiceAgreement: (
            state,
            action: PayloadAction<EnerbitServiceAgreement | null>,
        ) => {
            state.enerbitServiceAgreement = action.payload;
        },
        onEnerbitServiceAgreementChangedPlan: (
            state,
            action: PayloadAction<EnerbitServiceAgreement | null>,
        ) => {
            state.enerbitServiceAgreementPlan = action.payload;
        },
        onEnerbitStartedAtChanged: (
            state,
            action: PayloadAction<moment.Moment>,
        ) => {
            state.startedAt = action.payload.toDate().toString();
        },
        /* validatePlansState: (state, action: PayloadAction<EnerbitServiceAgreementPlanCreate>) => {
      const errors = validatePlans(action.payload);
    }, */
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEnerbitElectricitySupplyService.pending.type,
            (state) => {
                state.isLoadingEnerbitServiceAgreements = true;
                state.paginationEnerbitServiceAgreements = undefined;
            },
        );
        builder.addCase(
            getEnerbitElectricitySupplyService.rejected.type,
            (state) => {
                state.isLoadingEnerbitServiceAgreements = false;
            },
        );
        builder
            .addCase(
                getEnerbitElectricitySupplyService.fulfilled.type,
                (
                    state,
                    action: PayloadAction<
                        EnerbitPagination<EnerbitServiceAgreement>
                    >,
                ) => {
                    state.isLoadingEnerbitServiceAgreements = false;
                    state.paginationEnerbitServiceAgreements = action.payload;
                    if (
                        !state.enerbitServiceAgreement &&
                        action.payload.items.length > 0
                    ) {
                        state.enerbitServiceAgreement = action.payload.items[0];
                    }
                },
            )
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.pending,
                (state) => {
                    state.isLoadingListPlansEnergy = true;
                },
            )
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.fulfilled,
                (state, action) => {
                    state.isLoadingListPlansEnergy = false;
                    state.planList = action.payload;
                },
            )
            .addCase(
                getEnerbitElectricitySupplyServiceAgreements.rejected,
                (state) => {
                    state.isLoadingListPlansEnergy = false;
                    state.planList = undefined;
                },
            )
            .addCase(
                postEnerbitElectricitySupplyServiceAgreements.pending.type,
                (state) => {
                    state.isLoadingCreatePlansEnergy = true;
                },
            )
            .addCase(
                postEnerbitElectricitySupplyServiceAgreements.fulfilled.type,
                (
                    state,
                    action: PayloadAction<EnerbitServiceAgreementPlanCreate>,
                ) => {
                    state.successCreatePlansEnergy =
                        CreatePlansEnergyErrors.successCreatePlansEnergy;
                    state.errorCreatePlansEnergy = "";
                    state.isLoadingCreatePlansEnergy = false;
                },
            )
            .addCase(
                postEnerbitElectricitySupplyServiceAgreements.rejected.type,
                (state) => {
                    state.errorCreatePlansEnergy =
                        CreatePlansEnergyErrors.errorCreatePlansEnergy;
                    state.isLoadingCreatePlansEnergy = false;
                    state.successCreatePlansEnergy = "";
                    state.errorCreatePlansEnergy =
                        CreatePlansEnergyErrors.errorCreatePlansEnergy;
                },
            )
            .addCase(updateChangePlan.pending.type, (state) => {
                state.isLoadingUpdateChangePlan = true;
            })
            .addCase(
                updateChangePlan.fulfilled.type,
                (state, action: PayloadAction<string[]>) => {
                    state.isLoadingUpdateChangePlan = false;
                    if (action.payload.length > 0) {
                        state.errorUpdateChangePlan =
                            UpdateChangePlanEnum.errorUpdateChangePlan;
                        state.successUpdateChangePlan = "";
                    } else {
                        state.successUpdateChangePlan =
                            UpdateChangePlanEnum.successUpdateChangePlan;
                        state.errorUpdateChangePlan = "";
                    }
                },
            )
            .addCase(updateChangePlan.rejected.type, (state) => {
                state.isLoadingUpdateChangePlan = false;
                state.errorUpdateChangePlan =
                    UpdateChangePlanEnum.errorUpdateChangePlan;
                state.successUpdateChangePlan = "";
            });
    },
});

export const {
    setResetVarsCreatePlans,
    setResetVarsUpdatePlans,
    handlePageChange,
    setEnerbitServiceAgreement,
    onEnerbitServiceAgreementChangedPlan,
    onEnerbitStartedAtChanged,
} = makitaSlice.actions;

export const isLoadingListPlansEnergy = (state: StateStorage) =>
    state.makitaState.isLoadingListPlansEnergy;

export default makitaSlice.reducer;
