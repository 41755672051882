import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface CustomTimePickerProps {
  value: number;
  onChange: (value: number) => void;
  label?: string;
  minTime?: number;
  maxTime?: number;
  minutesStep?: number;
  ampm?: boolean;
  sx?: object;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  value,
  onChange,
  label,
  minTime = 0,
  maxTime = 24,
  minutesStep = 60,
  ampm = false,
  sx,
}) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedTime = event.target.value as number;
    onChange(selectedTime);
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = minTime; hour <= maxTime; hour++) {
      times.push(hour);
    }
    return times;
  };

  return (
    <FormControl sx={sx}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected === undefined) {
            return <em>Seleccione una hora</em>;
          }
          return selected.toString().padStart(2, "0") + ":00";
        }}
      >
        {generateTimeOptions().map((time, key) => (
          <MenuItem key={key} value={time}>
            {time.toString().padStart(2, "0") + ":00"}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomTimePicker;
