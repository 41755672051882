import { Box, CircularProgress } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyServiceAgreements } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { AppDispatch } from "../../store/store";
import { EmptyData } from "../empty-data";
import { CardEnerbitServiceAgreement } from "./CardEnerbitServiceAgreement";

const ListPlansMakita = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingListPlansEnergy, planList, page } = useSelector(
    (state: StateStorage) => state.makitaState
  );

  useEffect(() => {
    dispatch(getEnerbitElectricitySupplyServiceAgreements({}));
  }, []);

  if (isLoadingListPlansEnergy) {
    return (
      <Box className="Loading-flex">
        <CircularProgress />
      </Box>
    );
  }

  if (!planList || planList.items.length === 0) {
    return (
      <Box sx={{ mt: "2rem" }}>
        <EmptyData message={`No se encontraron planes.`} />
      </Box>
    );
  }

  return (
    <Box className="Container-plans-grid">
      {planList.items.map((planEnergy, index) => (
        <Box key={index}>
          <CardEnerbitServiceAgreement
            isChangePlan={false}
            seeDetail={true}
            key={planEnergy.id}
            enerbitServiceAgreement={planEnergy}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ListPlansMakita;
