import {
    EnerbitPagination,
    Market,
    Retailer,
    backEnerbitApi,
} from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getRetailers = createAsyncThunk(
    //action type string
    "[ELECTRICITY MARKET INFO] Get Retailer",
    //callback function
    async (_payload, _thunkAPI): Promise<EnerbitPagination<Retailer>> => {
        const { data, status } = await backEnerbitApi.get(
            `/electricity-market-info/electricity/retailer/`,
            {
                params: {
                    size: 300,
                },
            },
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getMarkets = createAsyncThunk(
    //action type string
    "[ELECTRICITY MARKET INFO] Get markets DSO",
    //callback function
    async (_payload, _thunkAPI): Promise<EnerbitPagination<Market>> => {
        const { data, status } = await backEnerbitApi.get(
            `/electricity-market-info/electricity/dso/`,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);
