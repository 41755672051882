import {
  Charge,
  ChargeConditions,
  PlanComponentName,
} from "../models/plan-administration/PlanAdministration";
import { convertDurationToHours } from "../utils/formatDates";

/**
 * Verifica si un plan es variable basado en los cargos.
 * @param charges - Los cargos del plan.
 * @returns `true` si el plan es variable, `false` en caso contrario.
 */
export const isVariablePlan = ({
  charges,
}: {
  charges?: Charge<ChargeConditions>[];
}): boolean => {
  const variableCharge = charges?.find((e) => e.name === "variable");

  if (!variableCharge) {
    return false;
  }
  return charges?.find((e) => e.name === "variable")?.conditions.intervals
    ?.length! >= 1 &&
    variableCharge.conditions.intervals?.some(
      (interval) => convertDurationToHours(interval.duration) < 24
    )
    ? true
    : false;
};

/**
 * Verifica si un plan tiene cargos variables sin intervalos.
 * @param charges - Los cargos del plan.
 * @returns `true` si el plan tiene cargos variables sin intervalos, `false` en caso contrario.
 */
export const hasVariableChargeWithoutIntervals = ({
  charges,
}: {
  charges?: Charge<ChargeConditions>[];
}): boolean => {
  return (
    charges?.find((e) => e.name === "variable")?.conditions.intervals
      ?.length! === 0 && true
  );
};

/**
 * Verifica si un plan tiene un cargo fijo con componentes.
 * @param charges - Los cargos del plan.
 * @returns `true` si el plan tiene un cargo fijo con componentes, `false` en caso contrario.
 */
export const hasFixedChargeWithComponents = (
  charges: Charge<ChargeConditions>[]
): boolean => {
  return charges?.some(
    (charge) =>
      charge.name === "fixed" &&
      ((charge.conditions.components &&
        charge.conditions.components.length > 0) ||
        charge.conditions.price !== undefined)
  );
};

/**
 * Verifica si un plan tiene herramientas tecnológicas como componente.
 * @param charges - Los cargos del plan.
 * @returns `true` si el plan tiene herramientas tecnológicas como componente, `false` en caso contrario.
 */
export const hasTechTools = (charges: Charge<ChargeConditions>[]): boolean => {
  return charges?.some(
    (charge) =>
      charge.name === "fixed" &&
      charge.conditions.components?.some(
        (component) =>
          component.name === PlanComponentName.TechTools ||
          component.name === "Herramientas tecnológicas"
      )
  );
};

/**
 * Verifica si un cargo es fijo.
 * @param charges - Los cargos del plan.
 * @returns `true` si el cargo no tiene componentes, o si tiene un solo componente con precio igual a cero, lo que indicaría que es un cargo fijo, `false` en caso contrario.
 */
export const isFixedCharge = (charges: Charge<ChargeConditions>[]): boolean => {
  return charges?.some(
    (charge) =>
      charge.name === "fixed" &&
      (!charge.conditions.components ||
        charge.conditions.components.length === 0 ||
        (charge.conditions.components.length === 1 &&
          charge.conditions.components.some(
            (component) => component.price === 0
          )))
  );
};
