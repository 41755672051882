import { AssetOwnerShip, FormikType } from "@enerbit/base";
import { CreatePreOffert } from "../models/Calculator";
import { OffersForm } from "../pages/offerts/OffersCreation";

export abstract class HelperFormCalculator {
    public static sumComponents = (formik: FormikType<OffersForm>): number => {
        let total = 0;
        const gComponent = Number.parseFloat(formik.values.gComponent);
        const tComponent = Number.parseFloat(formik.values.tComponent);
        const dComponent = Number.parseFloat(formik.values.dComponent);
        const cComponent = Number.parseFloat(formik.values.cComponent);
        const pComponent = Number.parseFloat(formik.values.pComponent);
        const rComponent = Number.parseFloat(formik.values.rComponent);
        if (!Number.isNaN(gComponent)) {
            total += gComponent;
        }
        if (!Number.isNaN(tComponent)) {
            total += tComponent;
        }
        if (!Number.isNaN(dComponent)) {
            total += dComponent;
        }
        if (!Number.isNaN(cComponent)) {
            total += cComponent;
        }
        if (!Number.isNaN(pComponent)) {
            total += pComponent;
        }
        if (!Number.isNaN(rComponent)) {
            total += rComponent;
        }
        return total;
    };

    public static parseAssetOwnership = (
        assetOwnership: AssetOwnerShip,
    ): string => {
        switch (assetOwnership.sui_code) {
            case "0":
                return "Los activos son propiedad del usuario";
            case "50":
                return "Los activos son de propiedad compartida";
            case "100":
                return "Los activos son propiedad del OR";
            case "101":
                return "El nivel de tensión del usuario es diferente a 1";
            default:
                return assetOwnership.description;
        }
    };
}

export const formatterValues = (values: OffersForm) => {
    return {
        tariff_option_value: values.tariffOption,
        tariff_component_values: {
            G: values.gComponent,
            T: values.tComponent,
            C: values.cComponent,
            D: values.dComponent,
            P: values.pComponent,
            R: values.rComponent,
        },
        service_data: {
            average_consumption: values.usageAverage,
            tension_level_id: values.tensionLevel,
            sui_social_stratum: values.socialStratum,
            assets_ownership: values.assetOwnership,
            subsistence_consumption: Number(values.subsistenceConsumption),
        },
        enerbit_tariff_id: values.tariffEnerbit,
        enerbit_service_agreement_id: values.enerbitServiceAgreement,
        contribution_flag: values.contributionFlag,
    };
};

export const getPercentPreOffert = (createPreOffert: CreatePreOffert) => {
    let percent = 5;
    if (createPreOffert?.savings_percentage) {
        if (createPreOffert?.savings_percentage!.plain_tariff_saving) {
            percent =
                createPreOffert?.savings_percentage.plain_tariff_saving! * 100;
        } else {
            percent =
                createPreOffert?.savings_percentage.tariff_option_saving! * 100;
        }
    }
    return Math.round(percent);
};
