import { Box, CircularProgress } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import { getSettlementSupplyTypeById } from "../../../store/actions/supplies/supplies";
import { AppDispatch } from "../../../store/store";

export const SupplyType = ({ supplyId }: { supplyId: string }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { isLoadingSettlementSupplyTypeById, settlementSupplyType } =
        useSelector((state: StateStorage) => state.supplyState);

    useEffect(() => {
        dispatch(getSettlementSupplyTypeById(supplyId));
    }, []);

    return (
        <Box>
            {isLoadingSettlementSupplyTypeById ? (
                <Box className="Loading-flex" sx={{ margin: 0 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box display="flex" sx={{ gap: "8px", alignItems: "center" }}>
                    {settlementSupplyType.description}
                </Box>
            )}
        </Box>
    );
};
