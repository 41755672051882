import {
    AssetOwnerShip,
    EnerbitPagination,
    SuiSocialStratum,
    TensionLevel,
    api,
} from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { formatterValues } from "../../../helpers/HelperFormCalculator";
import {
    ListPlans,
    ParamsTariffManager,
    ResponseTariff,
    RootPlans,
} from "../../../models/Calculator";
import {
    Charge,
    ChargeName,
    EnerbitServiceAgreement,
    EnerbitServiceAgreementPlanCreate,
    ParamsFilter,
    ServiceAgreementType,
} from "../../../models/Makita";
import { StateStorage } from "../../../models/StateStorage";
import { OffersForm } from "../../../pages/offerts/OffersCreation";

export const getEnerbitElectricitySupplyServiceAgreements = createAsyncThunk(
    "[Electricity Supply Service] Get Enerbit Electricity Supply Services Agreements",
    async (params?: ParamsFilter) => {
        let parameters: ParamsFilter = {
            size: 10,
            name: params?.pattern,
            page: params?.page,
            order_by_created_at: true,
        };

        if (params?.allPlans) {
            delete parameters.size;
        }

        let response = await api.get(
            `/electricity-supply-service/manager/enerbit-electricity-supply-services-agreements/`,
            {
                params: parameters,
            },
        );
        return response.data;
    },
);

export const postEnerbitElectricitySupplyServiceAgreements = createAsyncThunk(
    "[Electricity Supply Service] Post Enerbit Electricity Supply Services Agreements",
    async (params: EnerbitServiceAgreementPlanCreate) => {
        const charges: Charge[] = [];
        if (params.service_agreement_type === ServiceAgreementType.fijabit) {
            charges.push(
                {
                    name: ChargeName.Variable,
                    conditions: {
                        intervals: params.intervals.map((interval) => {
                            return {
                                ...interval,
                                price: params.priceCoverage,
                            };
                        }),
                    },
                },
                {
                    name: ChargeName.Fixed,
                    conditions: {
                        price: Number(params.priceBeforeVat),
                    },
                },
            );
        } else {
            charges.push(
                {
                    name: ChargeName.Variable,
                    conditions: {
                        intervals: params.intervals,
                    },
                },
                {
                    name: ChargeName.Fixed,
                    conditions: {
                        price: Number(params.priceBeforeVat),
                    },
                },
            );
        }

        const conditions = {
            cycle_begin: "string",
            cycle_end: "string",
            charges: charges,
        };
        const formData = new FormData();
        if (params.agreement_file) {
            formData.append("agreement_file", params.agreement_file);
        }
        formData.append("name", params.name);
        formData.append("description", params.description);
        formData.append(
            "service_agreement_type",
            params.service_agreement_type,
        );
        formData.append("conditions", JSON.stringify(conditions));

        const response = await api.post(
            `/electricity-supply-service/manager/enerbit-electricity-supply-services-agreements/`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        return response.data;
    },
);

export const getAssetOwnership = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Get Asset Ownership",
    //callback function
    async (_payload, _thunkAPI): Promise<AssetOwnerShip[]> => {
        const { data, status } = await api.get(
            `/electricity-supply-service/manager/asset-ownerships/`,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getSuiSocialStratums = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Get SUI Social Stratums",
    //callback function
    async (_payload, _thunkAPI): Promise<SuiSocialStratum[]> => {
        const { data, status } = await api.get(
            `electricity-supply-service/manager/sui-social-stratums/`,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getTensionLevel = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Get Tension level",
    //callback function
    async (_payload, _thunkAPI): Promise<TensionLevel[]> => {
        const { data, status } = await api.get(
            `electricity-supply-service/manager/voltage-levels/`,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const createPreOffers = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Create pre offerts",
    //callback function
    async (values: OffersForm, _thunkAPI): Promise<OffersForm> => {
        const formtatterValues = formatterValues(values);
        const { data, status } = await api.post(
            `electricity-supply-service/preoffers/preoffer-calculator-service`,
            formtatterValues,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getListPlans = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Get list plans",
    //callback function
    async (_, thunkAPI): Promise<RootPlans> => {
        const state = thunkAPI.getState() as StateStorage;
        const { data, status } = await updatePlanAxios(
            state.makitaState.enerbitServiceAgreement?.id ?? "",
            state.calculatorState.pageListPlans,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getTariffEnerbit = createAsyncThunk(
    //action type string
    "[ELECTRICITY SUPPLY SERVICES] Get tariff enerBit",
    //callback function
    async (
        values: ParamsTariffManager,
        _thunkAPI,
    ): Promise<ResponseTariff[]> => {
        const { data, status } = await api.get(
            `electricity-supply-service/tariff-manager/unit-cost/search-unit-cost/`,
        );
        if (status === 200 && data) {
            return data.items;
        }
        throw new Error("Data not found");
    },
);

export const getEnerbitElectricitySupplyService = createAsyncThunk(
    //action type string
    "[Electricicty Supply Service] Get Enerbit Electricicty Supply Service Agreements",
    //callback function
    async (
        _payload,
        _thunkAPI,
    ): Promise<EnerbitPagination<EnerbitServiceAgreement> | null> => {
        const { data, status } = await api.get(
            `electricity-supply-service/manager/enerbit-electricity-supply-service-agreements/`,
            {
                params: {
                    size: 300,
                },
            },
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

const updatePlanAxios = async (service_agreement_id: string, page: number) => {
    return await api.get(
        `electricity-supply-service/manager/electricity-supply-services/`,
        {
            params: {
                page: page,
                size: 10,
                enerbit_service_agreement_id: service_agreement_id,
            },
        },
    );
};

export const updateChangePlan = createAsyncThunk(
    //action type string
    "[Electricicty Supply Service] Update change plan",
    //callback function
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as StateStorage;
        const {
            enerbitServiceAgreementPlan,
            enerbitServiceAgreement,
            startedAt,
        } = state.makitaState;
        const { totalListPlans } = state.calculatorState;
        let errors = [];
        const totalPages = Math.ceil(totalListPlans / 10);
        for (let i = 0; i < totalPages; i++) {
            let services: ListPlans[] = [];
            if (i > 0) {
                const plans = await updatePlanAxios(
                    state.makitaState.enerbitServiceAgreement?.id ?? "",
                    i,
                );
                services = plans.data.items;
            } else {
                services = state.calculatorState.listPlans ?? [];
            }
            for await (let service of services) {
                try {
                    const startedAtDate = new Date(startedAt);
                    await api.post(
                        `electricity-supply-service/manager/enerbit-service-agreements-relationships/change-plan/`,
                        {
                            electricity_supply_service_id: service.id,
                            from_service_agreement_id:
                                enerbitServiceAgreement?.id,
                            to_service_agreement_id:
                                enerbitServiceAgreementPlan?.id,
                            started_at: moment(
                                startedAtDate.toISOString(),
                            ).format("YYYY-MM-DDTHH:mm:ssZ"),
                        },
                    );
                } catch (error) {
                    errors.push(`Id ${service.id}}`);
                }
            }
        }
        return errors;
    },
);
