import { IntervalWithEnd } from "../models/plan-administration/PlanAdministration";
import { formatHourToTwoDigits, getHourFromTime } from "../utils/formatDates";

export enum IntervalErrorType {
  Overlap = "overlap",
  Gap = "gap",
  SameStartEnd = "sameStartEnd",
  StartGreaterThanEnd = "startGreaterThanEnd",
}

interface IntervalCheckResult {
  isValid: boolean;
  type?: IntervalErrorType;
  interval?: IntervalWithEnd;
  previous?: IntervalWithEnd;
  current?: IntervalWithEnd;
  message?: string;
}

/**
 * Valida una lista de intervalos para asegurarse de que no haya errores como intervalos vacíos, superposiciones o intervalos mal definidos.
 * @param intervals - Un array de intervalos con hora de inicio y fin.
 * @returns Un objeto que indica si los intervalos son válidos y un mensaje de error si no lo son.
 */
export const validateIntervals = (
  intervals: IntervalWithEnd[]
): IntervalCheckResult => {
  if (intervals.length === 0) return { isValid: true, message: "" };

  const sortedIntervals = intervals
    .slice()
    .sort(
      (intervalA, intervalB) =>
        parseInt(intervalA.start) - parseInt(intervalB.start)
    );

  // Verificar que start y end no sean iguales en la misma posición
  for (let i = 0; i < intervals.length; i++) {
    if (intervals[i].start === intervals[i].end) {
      const message = "La hora inicial y la hora final no pueden ser iguales";
      return {
        isValid: false,
        type: IntervalErrorType.SameStartEnd,
        interval: intervals[i],
        message,
      };
    }
  }

  // Verificar que start no sea mayor que end en la misma posición
  for (let i = 0; i < intervals.length; i++) {
    if (parseInt(intervals[i].start) > parseInt(intervals[i].end)) {
      const message =
        "La hora de inicio debe ser antes que la de finalización. Recuerda elegir el horario dentro de las 24 horas del día.";
      return {
        isValid: false,
        type: IntervalErrorType.StartGreaterThanEnd,
        interval: intervals[i],
        message,
      };
    }
  }

  // Recorre los intervalos ordenados para verificar si hay espacios sin seleccionar
  for (let i = 1; i < sortedIntervals.length; i++) {
    const previous = sortedIntervals[i - 1];
    const current = sortedIntervals[i];

    // Verifica si hay un espacio sin seleccionar entre el final del intervalo anterior y el inicio del intervalo actual
    if (previous.end < current.start) {
      const message = `¡Ups! Te falta seleccionar un espacio entre las ${previous.end} y las ${current.start}. No olvides completar toda la franja horaria definida.`;
      return {
        isValid: false,
        type: IntervalErrorType.Gap,
        previous,
        current,
        message,
      };
    }
  }

  // Recorre los intervalos ordenados para verificar si hay superposiciones
  for (let i = 1; i < sortedIntervals.length; i++) {
    const previous = sortedIntervals[i - 1];
    const current = sortedIntervals[i];

    // Verifica si hay una superposición entre el final del intervalo anterior y el inicio del intervalo actual
    if (previous.end > current.start) {
      const message = `!Ups! revisa nuevamente, hay horarios que se cruzan o ya están seleccionados entre las ${formatHourToTwoDigits(
        previous.end
      )} y las ${formatHourToTwoDigits(current.start)}`;
      return {
        isValid: false,
        type: IntervalErrorType.Overlap,
        previous,
        current,
        message,
      };
    }
  }

  return { isValid: true, message: "" };
};

/**
 * Verifica si la suma total de horas de los intervalos es válida (al menos 24 horas).
 * @param intervals - Un array de intervalos con hora de inicio y fin.
 * @returns Un booleano que indica si la suma total de horas de los intervalos es válida.
 */
export const isTotalHoursIntervalsValid = (
  intervals: IntervalWithEnd[]
): boolean => {
  const totalHours = intervals.reduce((sum, interval) => {
    const start =
      typeof interval.start === "string"
        ? getHourFromTime(interval.start)
        : interval.start;
    const end =
      typeof interval.end === "string"
        ? getHourFromTime(interval.end)
        : interval.end;

    return sum + (end - start);
  }, 0);

  if (totalHours >= 24) {
    return true;
  }

  return false;
};

/**
 * Calcula el total de horas de una lista de intervalos.
 * @param intervals - Un array de intervalos con hora de inicio y fin.
 * @returns El total de horas calculado a partir de los intervalos.
 */
export const getTotalHoursInterval = (intervals: IntervalWithEnd[]): number => {
  const totalHours = intervals.reduce((sum, interval) => {
    if (!interval) {
      return sum;
    }

    const start =
      typeof interval.start === "string"
        ? getHourFromTime(interval.start)
        : interval.start;
    const end =
      typeof interval.end === "string"
        ? getHourFromTime(interval.end)
        : interval.end;

    return sum + (end - start);
  }, 0);

  return totalHours;
};

/**
 * Calcula la diferencia de horas entre dos tiempos dados.
 * @param start - La hora de inicio en formato de cadena.
 * @param end - La hora de fin en formato de cadena.
 * @returns La diferencia de horas entre la hora de inicio y la hora de fin.
 */
export const calculateHoursDifference = ({
  start,
  end,
}: {
  start: string;
  end: string;
}): number => {
  const startMinutes = getHourFromTime(start);
  const endMinutes = getHourFromTime(end);

  if (startMinutes === 0 && endMinutes === 23) {
    return 24;
  }

  // Calcular la diferencia de horas
  return endMinutes - startMinutes;
};
