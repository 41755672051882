import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    KeyboardArrowUpOutlinedIcon,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ISettlementSupplyPriod } from "../../../models/ISettlementSupplyPriod";
import { StateStorage } from "../../../models/StateStorage";
import { getSettlementSupplies } from "../../../store/actions/supplies/supplies";
import { AppDispatch } from "../../../store/store";
import { SupplyTable } from "../table/SupplyTable";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
        borderRadius: "12px 12px 0px 0px",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: enerbitColors.neutral[100],
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    borderRadius: "12px",
    padding: "16px",
    "& .MuiAccordionSummary-content": {
        margin: "0px",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: enerbitColors.neutral[100],
    borderTop: `1px solid ${enerbitColors.neutral[300]}`,
    borderRadius: "0px 0px 12px 12px",
}));

interface CustomizedAccordionProps {
    period: ISettlementSupplyPriod; // Replace 'any' with the actual type
    canCheck?: boolean;
}

export const CustomizedAccordion = ({
    period,
    canCheck = true,
}: CustomizedAccordionProps) => {
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const dispatch = useDispatch<AppDispatch>();

    const { isLoadingSettlementSupplies, settlementSupplies } = useSelector(
        (state: StateStorage) => state.supplyState,
    );

    useEffect(() => {
        dispatch(getSettlementSupplies());
    }, []);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{ margin: "3rem 0" }}
        >
            <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={null}
                sx={{
                    backgroundColor: "#FFF",
                    border: `1px solid ${enerbitColors.primary[400]}`,
                }}
            >
                <Grid
                    container
                    spacing={2}
                    className="content-header-accordion"
                    alignItems="center"
                >
                    <Grid item xs={0.5}>
                        <Button
                            onClick={() => {}}
                            sx={{
                                display: "flex",
                                padding: "0.5rem 0.3rem !important",
                                justifyItems: "center",
                                height: "1.8rem",
                                justifyContent: "center",
                                minWidth: "0 !important",
                                borderRadius: "8px !important",
                                background: enerbitColors.primary[100],
                            }}
                        >
                            {expanded === "panel1" ? (
                                <KeyboardArrowUpOutlinedIcon
                                    sx={{
                                        color: enerbitColors.neutral[700],
                                        fontSize: "17px",
                                    }}
                                />
                            ) : (
                                <KeyboardArrowDownOutlinedIcon
                                    sx={{
                                        color: enerbitColors.neutral[700],
                                        fontSize: "17px",
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className="content-checkbox-accordion">
                            {canCheck && (
                                <Checkbox
                                    checked={true}
                                    onChange={(_, checked) => {}}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )}

                            <Box className="card-title-accordion">
                                <Typography
                                    variant="h5"
                                    color={enerbitColors.primary.main}
                                    fontWeight="bold"
                                >
                                    {period.settlementPeriod}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: "#F4F1FA",
                    border: `1px solid ${enerbitColors.primary[400]}`,
                }}
            >
                {isLoadingSettlementSupplies ? (
                    <Box className="Loading-flex">
                        <CircularProgress />
                    </Box>
                ) : (
                    <SupplyTable settlementSuplies={settlementSupplies} />
                )}
            </AccordionDetails>
        </Accordion>
    );
};
