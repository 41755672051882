import { EnerbitPagination } from "@enerbit/base";

export interface EnerbitElectricitySupplyService {
    cuttable: boolean;
    started_at: Date;
    ended_at: Date | null;
    id: string;
    service_status: ServiceStatus;
    enerbit_service_agreement: EnerbitServiceAgreement;
    electricity_supply_service_id: string;
}
export interface ServiceStatus {
    name: string;
    description: string;
    id: string;
    is_located?: boolean;
}
export interface EnerbitServiceAgreementPlanCreate {
    name: string;
    description: string;
    technologicalToolsBeforeIva: number;
    priceBeforeVat: number;
    service_agreement_type: ServiceAgreementType;
    priceCoverage: number;
    intervals: Interval[];
    agreement_file: File | null;
}
export interface Interval {
    start: string;
    duration: string;
    price: number;
}
export interface EnerbitServiceAgreement {
    name: string;
    description: string;
    service_agreement_type: string;
    conditions: EnerbitServiceAgreementConditions;
    path_file: string;
    id?: string;
}
export interface EnerbitServiceAgreementConditions {
    cycle_begin: string;
    cycle_end: string;
    charges: Charge[];
}

export interface Charge {
    name: ChargeName;
    conditions: ChargeConditions;
}
export interface ChargeConditions {
    price?: number;
    intervals?: Interval[];
}

export enum ChargeName {
    Fixed = "fixed",
    Variable = "variable",
}

export enum ServiceAgreementType {
    dinabit = "dinabit",
    fijabit = "fijabit",
}

export enum UpdateChangePlanEnum {
    successUpdateChangePlan = "Cambio de plan exitoso",
    errorUpdateChangePlan = "Por favor revisa la lista nuevamente, alguno(s) de los servicios no pudieron ser actualizados",
}
export interface ParamsFilter {
    pattern?: string;
    size?: number;
    name?: string;
    page?: number;
    allPlans?: boolean;
    order_by_created_at?: true;
}

export interface MakitaState {
    params?: ParamsFilter;
    planList?: EnerbitPagination<EnerbitServiceAgreement>;
    isLoadingListPlansEnergy: boolean;
    isLoadingCreatePlansEnergy: boolean;
    successCreatePlansEnergy: string;
    errorCreatePlansEnergy: string;
    page: number;
    size: number;
    pages: number;
    next_page?: number;
    enerbitServiceAgreement: EnerbitServiceAgreement | null;
    paginationEnerbitServiceAgreements?: EnerbitPagination<EnerbitServiceAgreement>;
    isLoadingEnerbitServiceAgreements: boolean;
    enerbitServiceAgreementPlan: EnerbitServiceAgreement | null;
    isLoadingUpdateChangePlan: boolean;
    successUpdateChangePlan: string;
    errorUpdateChangePlan: string;
    startedAt: string;
}

export interface ParamsUpdatePlan {
    electricity_supply_service_id: string;
    from_service_agreement_id: string;
    to_service_agreement_id: string;
    user_type: string;
    user_id: string;
    started_at: string;
}

export interface ResponseUpdatePlan {
    user_id: string;
    user_type: string;
    enerbit_electricity_supply_service_id: string;
    enerbit_electricity_supply_service_agreement_id: string;
    fixed_enerbit_electricity_supply_services_id: string;
    started_at: string;
    ended_at: string;
    id: string;
    created_at: string;
    updated_at: string;
}

export enum CreatePlansEnergyErrors {
    successCreatePlansEnergy = "Plan creado exitosamente",
    errorCreatePlansEnergy = "Ocurrió un error, intentélo de nuevo",
}

export enum ChargeType {
    Fixed = "fixed",
    Variable = "variable",
}
