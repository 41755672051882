import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    enerbitColors,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import { setOpenDeleteFileDialog } from "../../../store/slices/supply.slice";
import { AppDispatch } from "../../../store/store";

export const DeleteFileDialog = () => {
    const dispatch = useDispatch<AppDispatch>();

    const { openDeleteFileDialog: openDeleteFileModal } = useSelector(
        (state: StateStorage) => state.supplyState,
    );

    const onClearFile = (): void => {
        dispatch(setOpenDeleteFileDialog(false));
    };

    return (
        <Dialog
            open={openDeleteFileModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ padding: "32px" }}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    textAlign: "center",
                    color: enerbitColors.primary.main,
                    fontSize: 24,
                }}
            >
                {"¿Desea eliminar el archivo?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Ten en cuenta que una vez eliminado, no se podrá recuperar.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button
                    onClick={() => {
                        onClearFile();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    style={{
                        backgroundColor: enerbitColors.secondary.orange.main,
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                        borderRadius: 12,
                        padding: "10px 32px",
                    }}
                >
                    Si, eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
