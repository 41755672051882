import {
    Box,
    Button,
    Grid,
    Typography,
    enerbitColors,
    formatterPeso,
} from "@enerbit/base";
import moment from "moment";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    ChargeName,
    EnerbitServiceAgreement,
    Interval,
} from "../../models/Makita";
import { setEnerbitServiceAgreement } from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";

// TODO: Pasar la funcion ItemInterval() al base
export type ItemIntervalProps = {
    interval: Interval;
    colorText?: string;
};
export const ItemInterval = ({ interval, colorText }: ItemIntervalProps) => {
    const _getLabelText = () => {
        const start = moment(interval.start, "HH:mm").format("HH:mm");
        const duration = `${interval.duration.slice(0, -1)}:00`;
        return `${start} a ${duration}`;
    };
    return (
        <Typography
            variant="body1"
            color={colorText ?? enerbitColors.neutral[700]}
        >
            {_getLabelText()} es de {formatterPeso.format(interval?.price)}
        </Typography>
    );
};

export type CardEnerbitServiceAgreementProps = {
    enerbitServiceAgreement: EnerbitServiceAgreement;
    backgroundColor?: string;
    onChangeModal?: () => void;
    hideTitle?: boolean;
    titleColor?: string;
    textColor?: string;
    centerItems?: boolean;
    isChangePlan?: boolean;
    seeDetail?: boolean;
};

export const CardEnerbitServiceAgreement = ({
    enerbitServiceAgreement,
    onChangeModal,
    hideTitle,
    isChangePlan,
    seeDetail,
    titleColor,
    textColor,
    centerItems,
}: CardEnerbitServiceAgreementProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Box
            sx={{
                width: "100%",
                borderBottom: 1,
                backgroundColor: isChangePlan
                    ? enerbitColors.neutral[100]
                    : "transparent",
                borderRadius: isChangePlan ? "16px" : "0px",
                padding: isChangePlan ? "12px 24px" : "0px",
                borderColor: hideTitle ? "transparent" : "#D8D8D8",
                mt: !hideTitle && !isChangePlan ? "20px" : undefined,
                paddingBottom: !hideTitle ? "8px" : undefined,
            }}
        >
            {!hideTitle && (
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    marginBottom="0.3rem"
                >
                    {enerbitServiceAgreement.name}
                </Typography>
            )}

            <Grid
                container
                sx={{ alignItems: centerItems ? "center" : "flex-start" }}
            >
                <Grid item xs={5}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={titleColor ?? enerbitColors.primary.main}
                    >
                        Herramientas tecnológicas:
                    </Typography>
                    <Typography
                        variant="body1"
                        color={textColor ?? enerbitColors.primary.main}
                    >
                        {enerbitServiceAgreement.conditions.charges.map(
                            (charge) => {
                                if (charge.name != ChargeName.Fixed) {
                                    return "";
                                }
                                return formatterPeso.format(
                                    charge?.conditions?.price ?? 0,
                                );
                            },
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={titleColor ?? enerbitColors.primary.main}
                    >
                        Cobertura:
                    </Typography>
                    <Box>
                        {enerbitServiceAgreement.conditions.charges.map(
                            (charge, index) => {
                                if (charge.name == ChargeName.Fixed) {
                                    return <Fragment key={index}></Fragment>;
                                }
                                return (
                                    <Box key={charge.name}>
                                        {charge.conditions?.intervals?.map(
                                            (interval) => {
                                                return (
                                                    <ItemInterval
                                                        key={interval.start}
                                                        interval={interval}
                                                        colorText={
                                                            textColor ??
                                                            enerbitColors
                                                                .primary.main
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </Box>
                                );
                            },
                        )}
                    </Box>
                </Grid>
                {seeDetail && (
                    <Grid item xs={2} md={2}>
                        <Button
                            name="buttonSeeDetails"
                            variant="outlined"
                            color="primary"
                            className="Button-list-makita"
                            size="small"
                            onClick={() => {
                                dispatch(
                                    setEnerbitServiceAgreement(
                                        enerbitServiceAgreement,
                                    ),
                                );
                                onChangeModal
                                    ? onChangeModal()
                                    : navigate(
                                          `/detail/${enerbitServiceAgreement.id}`,
                                      );
                            }}
                            sx={{ width: "65%", height: "2.5rem" }}
                        >
                            <Typography
                                fontWeight="700"
                                margin="0"
                                color={enerbitColors.primary.main}
                            >
                                Ver Detalle
                            </Typography>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
