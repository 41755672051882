import { PlanComponentName } from "../models/plan-administration/PlanAdministration";

/**
 * Traduce el nombre de un componente de plan a su equivalente en español.
 * @param name - El nombre del componente de plan en inglés.
 * @returns El nombre traducido en español.
 */
export const translatePlanComponentName = (name: string): string => {
  switch (name) {
    case PlanComponentName.TechTools:
      return "Herramientas tecnológicas";
    case PlanComponentName.MeterRent:
      return "Alquiler del medidor";
    default:
      return name;
  }
};
