import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { socketMiddleware } from "../middleware/socket";
import calculatorReducer from "./slices/calculator";
import makitaReducer from "./slices/makitaSlice";
import supplyReducer from "./slices/supply.slice";
import supplyFormReducer from "./slices/supplyForm.slice";
import { Socket } from "@enerbit/base/common/utils/socket";

const rootReducer = combineReducers({
    makitaState: makitaReducer,
    calculatorState: calculatorReducer,
    supplyState: supplyReducer,
    supplyFormState: supplyFormReducer,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(socketMiddleware(new Socket())),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
