import {
    Autocomplete,
    Box,
    Button,
    DatePicker,
    FormGroup,
    Grid,
    InputLabel,
    LocalizationProvider,
    TextField,
    Typography,
    enerbitColors,
    useFormik,
    yup,
} from "@enerbit/base";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyService } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import {
    onEnerbitServiceAgreementChangedPlan,
    onEnerbitStartedAtChanged,
} from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import { CardEnerbitServiceAgreement } from "../reusable/CardEnerbitServiceAgreement";

export type FormChangeModalProps = {
    handleClose?: (
        event: {},
        reason: "backdropClick" | "escapeKeyDown",
    ) => void;
    handleChangePage: () => void;
};

export const FormChangeModal = ({
    handleClose,
    handleChangePage,
}: FormChangeModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        paginationEnerbitServiceAgreements,
        enerbitServiceAgreementPlan,
        isLoadingEnerbitServiceAgreements,
        startedAt,
    } = useSelector((state: StateStorage) => state.makitaState);

    const formik = useFormik({
        initialValues: {
            enerbitServiceAgreementPlan: "",
        },
        validationSchema: yup.object({
            enerbitServiceAgreementPlan: yup
                .string()
                .required("Este campo es requerido"),
        }),
        onSubmit: (_) => {
            handleChangePage();
        },
    });

    useEffect(() => {
        dispatch(getEnerbitElectricitySupplyService());
    }, []);

    const formattedStartedAt = moment(startedAt, moment.ISO_8601).isValid()
        ? moment(startedAt).toISOString()
        : null;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }}>
                <FormGroup>
                    <InputLabel
                        shrink
                        className="Input-label"
                        sx={{ fontSize: "16px" }}
                    >
                        ¿Hacia qué plan cambiarás los servicios que están en
                        este?
                    </InputLabel>
                    <Autocomplete
                        id="enerbitElectricitySupplyServiceId"
                        fullWidth
                        loading={isLoadingEnerbitServiceAgreements}
                        options={
                            paginationEnerbitServiceAgreements?.items ?? []
                        }
                        value={enerbitServiceAgreementPlan}
                        autoHighlight
                        getOptionLabel={(option) => option?.name ?? ""}
                        renderOption={(props, option, state) => (
                            <li {...props} key={option.id}>
                                <CardEnerbitServiceAgreement
                                    isChangePlan={false}
                                    enerbitServiceAgreement={option}
                                />
                            </li>
                        )}
                        onChange={(_e, value) => {
                            if (value) {
                                formik.setFieldValue(
                                    "enerbitServiceAgreementPlan",
                                    value.id,
                                );
                                dispatch(
                                    onEnerbitServiceAgreementChangedPlan(value),
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="enerbitElectricitySupplyServiceId"
                                className="TextField-without-border-radius"
                                variant="outlined"
                                error={
                                    formik.touched
                                        .enerbitServiceAgreementPlan &&
                                    Boolean(
                                        formik.errors
                                            .enerbitServiceAgreementPlan,
                                    )
                                }
                                helperText={
                                    formik.touched
                                        .enerbitServiceAgreementPlan &&
                                    formik.errors.enerbitServiceAgreementPlan
                                }
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />
                </FormGroup>
                <FormGroup>
                    <InputLabel shrink className="Input-label">
                        Mes
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            onChange={(value: moment.Moment | null) => {
                                if (value) {
                                    dispatch(
                                        onEnerbitStartedAtChanged(
                                            moment(value).startOf("month"),
                                        ),
                                    );
                                }
                            }}
                            views={["month", "year"]}
                            openTo="month"
                            value={
                                formattedStartedAt
                                    ? moment(formattedStartedAt)
                                    : null
                            }
                        />
                    </LocalizationProvider>
                </FormGroup>
                <Box
                    sx={{
                        display: "flex",
                        marginTop: "17px",
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        color={enerbitColors.error.main}
                        fontWeight={900}
                    >
                        ¿No encuentras el plan? Recuerda debe estar previamente
                        creado
                    </Typography>
                </Box>
                {enerbitServiceAgreementPlan && (
                    <Box sx={{ mt: "20px" }}>
                        <CardEnerbitServiceAgreement
                            isChangePlan={true}
                            enerbitServiceAgreement={
                                enerbitServiceAgreementPlan
                            }
                        />
                    </Box>
                )}
                <Grid container spacing={4} sx={{ marginTop: "0px" }}>
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                if (handleClose) {
                                    handleClose({}, "backdropClick");
                                }
                            }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
