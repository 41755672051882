import {
    AddIcon,
    Box,
    Button,
    CustomAlert,
    IconButton,
    InfoIcon,
    SearchIcon,
    TextField,
    Typography,
    useFormik,
    yup,
} from "@enerbit/base";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialValues } from "../../models/MakitaSearchState";
import { CustomModalTypes } from "../../models/ModalProps";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyServiceAgreements } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { setResetVarsCreatePlans } from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import ListPlansMakita from "../reusable/ListPlansMakita";
import CreateModalPlan from "../reusable/modal/CreateModalPlan";

const SearchMakita = () => {
    const createClient = useRef<CustomModalTypes>(null);
    const textInput = useRef<typeof TextField>(null);
    const dispatch = useDispatch<AppDispatch>();
    let timeout: NodeJS.Timeout;

    let initialValues: InitialValues = {
        param: "",
    };

    const validationSchema = yup.object({
        param: yup.string().required("Campo de búsqueda es requerido"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values: InitialValues) => {
            return;
        },
    });

    const callCreateClient = () => {
        if (createClient.current) {
            createClient.current.changeModal();
        }
    };

    const _placeholderSearchTextField = (): string => {
        return "Búsqueda por nombre";
    };
    const { errorCreatePlansEnergy, successCreatePlansEnergy } = useSelector(
        (state: StateStorage) => state.makitaState,
    );

    return (
        <Box
            sx={{
                paddingBottom: "3rem",
            }}
        >
            {errorCreatePlansEnergy != "" && (
                <CustomAlert
                    onClose={() => {
                        dispatch(setResetVarsCreatePlans());
                    }}
                    sx={{ width: "100%", mt: "1rem" }}
                    severity="error"
                    text={errorCreatePlansEnergy}
                />
            )}
            {successCreatePlansEnergy != "" && (
                <CustomAlert
                    onClose={() => {
                        dispatch(setResetVarsCreatePlans());
                    }}
                    sx={{ width: "100%", mt: "1rem" }}
                    severity="success"
                    text={successCreatePlansEnergy}
                />
            )}
            <Box className="Container-search">
                <Box>
                    <Box className="Box-search-main">
                        <Box className="Header-makita">
                            <Typography variant="h4">
                                ¿Qué tipo de plan deseas consultar?
                            </Typography>
                            <Button
                                id="Button-create-plan"
                                name="buttonCreatePlan"
                                color="warning"
                                size="small"
                                className="Loading-button Button-create-plan"
                                onClick={() => callCreateClient()}
                                sx={{ width: "12%", height: "2.8rem" }}
                            >
                                <Box className="Button-text-create-plan">
                                    <Box>
                                        <Box className="Icon-plus-create-plan">
                                            <AddIcon className="Icon-item" />
                                        </Box>
                                    </Box>
                                    <Typography
                                        variant="button"
                                        fontWeight="700"
                                        textTransform="none"
                                    >
                                        Crear
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                        <Box
                            className="TextField-without-border-radius"
                            sx={{ marginTop: "40px" }}
                        >
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    id="Input-search-type-plan"
                                    name="inputTextSearchTypePlan"
                                    inputRef={textInput}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                    }}
                                    placeholder={_placeholderSearchTextField()}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        // TODO: Pasar esto al base
                                        clearInterval(timeout);
                                        timeout = setInterval(() => {
                                            dispatch(
                                                getEnerbitElectricitySupplyServiceAgreements(
                                                    {
                                                        pattern:
                                                            event.target.value,
                                                    },
                                                ),
                                            );
                                            clearInterval(timeout);
                                        }, 500);
                                    }}
                                    error={
                                        formik.touched.param &&
                                        Boolean(formik.errors.param)
                                    }
                                    helperText={
                                        formik.touched.param &&
                                        formik.errors.param
                                    }
                                    sx={{
                                        width: "100%",
                                        borderRadius: "14px",
                                        background: "white",
                                    }}
                                />
                            </form>
                            <Box className="Container-text-info-search">
                                <InfoIcon className="Info-icon" />
                                <Typography
                                    className="Box-search-text-makita"
                                    fontWeight="400"
                                >
                                    El valor mostrado en Herramientas
                                    Tecnológicas (Fijo), no incluye IVA.
                                </Typography>
                            </Box>
                            <CreateModalPlan ref={createClient} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ListPlansMakita />
        </Box>
    );
};

export default SearchMakita;
