export enum ChargeName {
  Fixed = "fixed",
  Variable = "variable",
}

export enum ServiceAgreementType {
  dinabit = "dinabit",
  fijabit = "fijabit",
}

export enum UpdateChangePlanEnum {
  successUpdateChangePlan = "Cambio de plan exitoso",
  errorUpdateChangePlan = "Por favor revisa la lista nuevamente, alguno(s) de los servicios no pudieron ser actualizados",
}

export enum PlanFixedComponentsName {
  MeterRent = "Alquiler del medidor",
  TechTool = "Herramientas tecnológicas",
}

export const planDescriptionsName: { [key: string]: string } = {
  meter_rent: "Alquiler del medidor",
  tech_tools: "Herramientas tecnológicas",
};
export const getPlanDescription = (planName: string): string => {
  return planDescriptionsName[planName] || "Descripción no disponible";
};
