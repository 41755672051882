export enum PlanComponentName {
  TechTools = "tech_tools",
  MeterRent = "meter_rent",
}

export interface Interval {
  start: string;
  duration: string;
  price: number;
}

export interface IntervalWithEnd extends Interval {
  price: number;
  end: string;
}

export interface EnerbitServiceAgreement<T> {
  name: string;
  description: string;
  service_agreement_type: string;
  conditions: EnerbitServiceAgreementConditions<T>;
  path_file: string;
  id?: string;
}
export interface EnerbitServiceAgreementConditions<T> {
  cycle_begin: string;
  cycle_end: string;
  charges: Charge<T>[];
  price?: number;
}

export interface Charge<T> {
  name: string;
  conditions: T;
}

export interface Interval {
  start: string;
  duration: string;
  price: number;
}

export interface ChargeConditions {
  price?: number;
  intervals?: Interval[];
  components?: {
    name: string;
    price: number;
  }[];
}

export enum CreatePlansEnergyErrors {
  successCreatePlansEnergy = "Plan creado exitosamente",
  errorCreatePlansEnergy = "Ocurrió un error, intentélo de nuevo",
}
