/**
 * Formatea un número como una cadena en formato de moneda colombiana (COP) con dos decimales.
 * @param price - El número a formatear.
 * @returns La cadena formateada en formato de moneda colombiana con dos decimales.
 */
export const formatPriceToCOP = (price: number): string => {
  return price.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Formatea un número como una cadena en formato de moneda colombiana (COP) sin decimales.
 * @param price - El número a formatear.
 * @returns La cadena formateada en formato de moneda colombiana sin decimales.
 */
export const formatPriceToCOPWithoutDecimals = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
