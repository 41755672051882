import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  CustomAlert,
  Grid,
  IconButton,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { StateStorage } from "../../models/StateStorage";
import { getMarkets } from "../../store/actions/electricity-market-info/electricity-market-info.actions";
import {
  onEnerbitServiceAgreementChangedPlan,
  setResetVarsUpdatePlans,
} from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import DetailsPlan from "./DetailsPlan";
import { ModalChangePlan } from "./ModalChangePlan";
import { ServicesPlan } from "./ServicesPlan";

const SectionDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const { errorUpdateChangePlan, successUpdateChangePlan } = useSelector(
    (state: StateStorage) => state.makitaState
  );

  useEffect(() => {
    dispatch(onEnerbitServiceAgreementChangedPlan(null));
    dispatch(setResetVarsUpdatePlans());
    dispatch(getMarkets());
  }, []);

  useEffect(() => {
    if (errorUpdateChangePlan != "" || successUpdateChangePlan != "") {
      setOpen(false);
    }
  }, [errorUpdateChangePlan, successUpdateChangePlan]);

  return (
    <Box className="Container-makita">
      <Grid container spacing={2}>
        {(errorUpdateChangePlan != "" || successUpdateChangePlan != "") && (
          <CustomAlert
            onClose={() => {
              dispatch(setResetVarsUpdatePlans());
            }}
            sx={{ width: "100%", mt: "1rem" }}
            severity={errorUpdateChangePlan != "" ? "error" : "success"}
            text={
              errorUpdateChangePlan != ""
                ? errorUpdateChangePlan
                : successUpdateChangePlan
            }
          />
        )}
        <Grid item xs={12} md={5}>
          <Box className="Title-description-iva" sx={{ mb: "10px" }}>
            <IconButton sx={{ mr: "10px" }} onClick={() => navigate("/")}>
              <ArrowCircleLeftOutlinedIcon
                sx={{
                  color: enerbitColors.primary.main,
                  fontSize: 38,
                }}
              />
            </IconButton>
            <Box>
              <Typography
                color={enerbitColors.primary.main}
                fontWeight={700}
                fontSize={28}
              >
                Detalle
              </Typography>
            </Box>
          </Box>
          <Box className="Container-box-name-plan">
            <DetailsPlan />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <ServicesPlan openModal={() => setOpen(true)} />
        </Grid>
        <ModalChangePlan
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />
      </Grid>
    </Box>
  );
};

export default SectionDetail;
