import { Box, Typography } from "@enerbit/base";
export interface TitleCreationCoverageProps {
    title: string;
}

export const TitleCreationCoverage = ({
    title,
}: TitleCreationCoverageProps) => {
    return (
        <Box
            sx={{
                backgroundColor: "var(--color-neutral100)",
                borderRadius: "8px",
                padding: "0.75rem 0.875rem",
            }}
        >
            <Typography
                variant="body1"
                textAlign="center"
                color="var(--neutral-500)"
                fontWeight="bold"
            >
                {title}
            </Typography>
        </Box>
    );
};
