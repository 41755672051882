import {
  Box,
  CardPdf,
  InfoIcon,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useSelector } from "react-redux";

import {
  hasFixedChargeWithComponents,
  hasTechTools,
  hasVariableChargeWithoutIntervals,
  isVariablePlan,
} from "../../helpers/planHelpers";
import { StateStorage } from "../../models/StateStorage";
import { MonthlyFixedChargeTable } from "./MonthlyFixedChargeTable";
import PlanCoverageDetails from "./PlanCoverageDetails";

const DetailsPlan = () => {
  const { enerbitServiceAgreement } = useSelector(
    (state: StateStorage) => state.makitaState
  );
  const charges = enerbitServiceAgreement?.conditions.charges;
  const isVariable = isVariablePlan({ charges });
  const hasNoIntervals = hasVariableChargeWithoutIntervals({ charges });
  const isFixed = !isVariable && !hasNoIntervals;

  return (
    <>
      <Box className="Detail-plan-box">
        <Typography
          className="Title-name-plan"
          color={enerbitColors.primary.main}
        >
          {enerbitServiceAgreement?.name}
        </Typography>
        {/* <Box sx={{ background: enerbitColors.success[100] }} className="Box-status-plan">
          <Typography color={enerbitColors.success.main}>
            Activo
          </Typography>
        </Box> */}
        <Box sx={{ mb: "32px", mt: "20px" }}>
          <Typography color={enerbitColors.primary.main} fontWeight={700}>
            Descripción:
          </Typography>
          <Typography color={enerbitColors.neutral.main} lineHeight={"17px"}>
            {enerbitServiceAgreement?.description}
          </Typography>
        </Box>

        {enerbitServiceAgreement &&
          hasFixedChargeWithComponents(
            enerbitServiceAgreement.conditions.charges
          ) && (
            <>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {MonthlyFixedChargeTable(enerbitServiceAgreement)}
              </Box>
            </>
          )}

        {enerbitServiceAgreement &&
        enerbitServiceAgreement.conditions.charges &&
        hasTechTools(enerbitServiceAgreement.conditions.charges) ? null : (
          <Box className="Title-description-iva">
            <Box>
              <InfoIcon sx={{ color: "var(--color-error500)", mr: "12px" }} />
            </Box>
            <Box>
              <Typography
                sx={{ color: "var(--color-error500)", fontWeight: "bold" }}
                fontSize={"16px"}
              >
                Este plan no incluye herramientas tecnológicas.
              </Typography>
              <Typography
                sx={{ color: "var(--color-error500)" }}
                fontSize={"16px"}
              >
                Los usuarios no podrán ver su consumo en tiempo real ni acceder
                a otras funcionalidades.
              </Typography>
            </Box>
          </Box>
        )}
        {isVariable ? (
          <PlanCoverageDetails
            isVariablePlan={isVariablePlan({
              charges: enerbitServiceAgreement?.conditions.charges,
            })}
            enerbitServiceAgreement={enerbitServiceAgreement}
          />
        ) : hasNoIntervals ? null : isFixed ? (
          <PlanCoverageDetails
            isVariablePlan={isVariablePlan({
              charges: enerbitServiceAgreement?.conditions.charges,
            })}
            enerbitServiceAgreement={enerbitServiceAgreement}
          />
        ) : null}

        <Box sx={{ mt: "30px" }}>
          <CardPdf
            typeFile=".md"
            isSuccess={true}
            downloadUrl={enerbitServiceAgreement?.path_file}
            bgColor="success"
            fileInfo={{
              name:
                enerbitServiceAgreement?.name ??
                "Nombre de archivo no encontrado",
              size: 12,
            }}
            onClearFile={() => {}}
            progress={0}
          />
        </Box>
      </Box>
    </>
  );
};

export default DetailsPlan;
