import { Dayjs } from "@enerbit/base";

/**
 * Obtiene la hora de una cadena de tiempo en el formato "HH:mm:ss".
 * @param time - La cadena de tiempo.
 * @returns La hora como un número entero.
 */
export const getHourFromTime = (time: string) => {
  const numberHour = parseInt(time.split(":")[0]);
  return numberHour;
};

/**
 * Formatea la hora a dos dígitos.
 * @param time - La cadena de tiempo.
 * @returns La hora formateada a dos dígitos.
 */
export const formatHourToTwoDigits = (time: string) => {
  const numberHour = time.split(":")[0];
  return numberHour.padStart(2, "0");
};

/**
 * Convierte un valor de tiempo a una cadena en el formato "HH:mm:ss".
 * Si el valor ya es una cadena, lo devuelve tal cual.
 * @param timeValue - El valor de tiempo a convertir.
 * @returns La cadena de tiempo en el formato "HH:mm:ss".
 */
export const formatTimeToHHMMSS = (
  timeValue: Dayjs | string | null
): string => {
  if (!timeValue) {
    return "";
  }
  return typeof timeValue === "string"
    ? timeValue
    : timeValue.format("HH:mm:ss");
};

/**
 * Convierte una duración en formato "HH", "HH:mm:ss" o "H" a horas.
 * @param duration - La duración en formato "HH", "HH:mm:ss" o "H".
 * @returns La duración en horas.
 */
export const convertDurationToHours = (duration: string): number => {
  if (duration.endsWith("H")) {
    const hours = parseFloat(duration.slice(0, -1));
    return hours;
  }

  // Verificar si la duración está en el formato "HH:mm:ss"
  const [hours, minutes, seconds] = duration.split(":").map(Number);
  const totalHours = hours + (minutes || 0) / 60 + (seconds || 0) / 3600;
  return totalHours;
};
