import { Box, enerbitColors, Grid, Typography } from "@enerbit/base";
import {
  calculateFixedMonthlyChargeValue,
  calculateIvaFromColumnValue,
  calculateTotalMonthlyCharge,
  calculateValueIvaTable,
  calculateValueTotalRow,
} from "../../helpers/CalculateMonthlyFixedCharge";
import {
  ChargeConditions,
  EnerbitServiceAgreement,
} from "../../models/plan-administration/PlanAdministration";
import { formatPriceToCOPWithoutDecimals } from "../../utils/formatPrice";
import { translatePlanComponentName } from "../../utils/translatePlanComponentName";

export const MonthlyFixedChargeTable = (
  detailsPlan: EnerbitServiceAgreement<ChargeConditions> | null
) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderBottom: 1,
          backgroundColor: "transparent",
          borderColor: "#D8D8D8",
          mt: "20px",
          paddingBottom: "0.3rem",
          display: "flex",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              Concepto
            </Typography>
            <Typography variant="body1" color={enerbitColors.primary.main}>
              {detailsPlan?.conditions.charges.map((element: any) => {
                if (element) {
                  return "";
                }
                return;
              })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              Valor
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              IVA
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              Total
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <>
        {detailsPlan?.conditions.charges.map((charge, index) => {
          if (charge.name === "fixed") {
            if (charge.conditions && charge.conditions.components) {
              return charge.conditions.components.map(
                (chargeItem, compIndex) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: 1,
                        backgroundColor: "transparent",
                        borderColor: "#D8D8D8",
                        mt: "1rem",
                        display: "flex",
                      }}
                      key={`${index}-${compIndex}`}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0.3rem 1rem !important",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={enerbitColors.neutral[700]}
                          >
                            {translatePlanComponentName(chargeItem.name)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "0rem !important",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={enerbitColors.neutral[700]}
                          >
                            {formatPriceToCOPWithoutDecimals.format(
                              chargeItem.price
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "0rem !important",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={enerbitColors.neutral[700]}
                          >
                            {calculateValueIvaTable(chargeItem.price)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "0rem !important",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={enerbitColors.neutral[700]}
                          >
                            {formatPriceToCOPWithoutDecimals.format(
                              calculateValueTotalRow(chargeItem.price) +
                                chargeItem.price
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }
              );
            } else if (charge.conditions.price !== undefined) {
              return (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    backgroundColor: "transparent",
                    borderColor: "#D8D8D8",
                    mt: "1rem",
                    display: "flex",
                  }}
                  key={index}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.3rem 1rem !important",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={enerbitColors.neutral[700]}
                      >
                        Cargo fijo
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "0rem !important",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={enerbitColors.neutral[700]}
                      >
                        {formatPriceToCOPWithoutDecimals.format(
                          charge.conditions.price
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "0rem !important",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={enerbitColors.neutral[700]}
                      >
                        {calculateValueIvaTable(charge.conditions.price)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "0rem !important",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={enerbitColors.neutral[700]}
                      >
                        {formatPriceToCOPWithoutDecimals.format(
                          calculateValueTotalRow(charge.conditions.price) +
                            charge.conditions.price
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            }
          }
          return null;
        })}
      </>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "transparent",
          margin: "1.1rem 0 1.5rem 0",
          paddingBottom: "0.3rem",
          display: "flex",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              Cargo fijo mensual
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                color={enerbitColors.primary.main}
              >
                {calculateFixedMonthlyChargeValue(
                  detailsPlan?.conditions.charges ?? []
                )}
              </Typography>
            </>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              {calculateIvaFromColumnValue(
                detailsPlan?.conditions.charges || []
              )}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={enerbitColors.primary.main}
            >
              {calculateTotalMonthlyCharge(
                detailsPlan?.conditions.charges ?? []
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
