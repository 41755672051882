import { Box, Button, Grid, Typography, enerbitColors } from "@enerbit/base";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import React from "react";
import { ChargeName } from "../../helpers/HelpersPlanAdministration";
import { calculateEndTimeFromDuration } from "../../helpers/IntervalPlanHelpers";
import { isFixedCharge } from "../../helpers/planHelpers";
import {
  ChargeConditions,
  EnerbitServiceAgreement,
  Interval,
} from "../../models/plan-administration/PlanAdministration";
import { setEnerbitServiceAgreement } from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import { formatPriceToCOPWithoutDecimals } from "../../utils/formatPrice";
import { translatePlanComponentName } from "../../utils/translatePlanComponentName";

// TODO: Pasar la funcion ItemInterval() al base
export type ItemIntervalProps = {
  interval: Interval;
  colorText?: string;
};
export const ItemInterval = ({ interval, colorText }: ItemIntervalProps) => {
  const _getLabelText = () => {
    const start = moment(interval.start, "HH:mm").format("HH:mm");
    const duration = calculateEndTimeFromDuration({
      start: interval.start,
      duration: interval.duration,
    }).slice(0, -3);
    return `${start} a ${duration}`;
  };
  return (
    <Typography variant="body1" color={colorText ?? enerbitColors.neutral[700]}>
      {_getLabelText()} es de{" "}
      {formatPriceToCOPWithoutDecimals.format(interval?.price)}
    </Typography>
  );
};

export type CardEnerbitServiceAgreementProps = {
  enerbitServiceAgreement: EnerbitServiceAgreement<ChargeConditions>;
  backgroundColor?: string;
  onChangeModal?: () => void;
  hideTitle?: boolean;
  titleColor?: string;
  textColor?: string;
  centerItems?: boolean;
  isChangePlan?: boolean;
  seeDetail?: boolean;
};

export const CardEnerbitServiceAgreement = ({
  enerbitServiceAgreement,
  onChangeModal,
  hideTitle,
  isChangePlan,
  seeDetail,
  titleColor,
  textColor,
  centerItems,
}: CardEnerbitServiceAgreementProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: 1,
        backgroundColor: isChangePlan
          ? enerbitColors.neutral[100]
          : "transparent",
        borderRadius: isChangePlan ? "16px" : "0px",
        padding: isChangePlan ? "12px 24px" : "0px",
        borderColor: hideTitle ? "transparent" : "#D8D8D8",
        mt: !hideTitle && !isChangePlan ? "20px" : undefined,
        paddingBottom: !hideTitle ? "8px" : undefined,
      }}
    >
      {!hideTitle && (
        <Typography variant="body1" fontWeight="bold" marginBottom="0.3rem">
          {enerbitServiceAgreement.name}
        </Typography>
      )}

      <Grid
        container
        sx={{ alignItems: centerItems ? "center" : "flex-start" }}
      >
        <Grid item xs={5}>
          {enerbitServiceAgreement.conditions.charges.map(
            (charge, chargeIndex) => {
              if (charge.name !== ChargeName.Fixed) {
                return null;
              }
              if (isFixedCharge([charge])) {
                return (
                  <React.Fragment key={chargeIndex}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={titleColor ?? enerbitColors.primary.main}
                    >
                      Cargo fijo:
                    </Typography>
                    <Typography
                      variant="body1"
                      color={textColor ?? enerbitColors.primary.main}
                    >
                      {formatPriceToCOPWithoutDecimals.format(
                        charge?.conditions?.price ?? 0
                      )}{" "}
                      {"+ IVA"}
                    </Typography>
                  </React.Fragment>
                );
              }
              const hasComponents =
                charge.conditions.components &&
                charge.conditions.components.length > 0;
              if (hasComponents) {
                return charge.conditions.components?.map(
                  (component, componentIndex) => (
                    <React.Fragment key={componentIndex}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={titleColor ?? enerbitColors.primary.main}
                      >
                        {translatePlanComponentName(component.name)}:
                      </Typography>
                      <Typography
                        variant="body1"
                        color={textColor ?? enerbitColors.primary.main}
                      >
                        {formatPriceToCOPWithoutDecimals.format(
                          component.price ?? 0
                        )}{" "}
                        {"+ IVA"}
                      </Typography>
                    </React.Fragment>
                  )
                );
              }
            }
          )}
        </Grid>
        {enerbitServiceAgreement.conditions.charges.some(
          (charge) =>
            charge.conditions?.intervals &&
            charge.conditions.intervals.length > 0
        ) ? (
          <Grid item xs={5}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={titleColor ?? enerbitColors.primary.main}
            >
              Cobertura:
            </Typography>
            <Box>
              {enerbitServiceAgreement.conditions.charges.map(
                (charge, index) => {
                  if (charge.name === ChargeName.Fixed) {
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                  return (
                    <Box key={charge.name}>
                      {charge.conditions?.intervals?.map((interval) => {
                        return (
                          <ItemInterval
                            key={interval.start}
                            interval={interval}
                            colorText={textColor ?? enerbitColors.primary.main}
                          />
                        );
                      })}
                    </Box>
                  );
                }
              )}
            </Box>
          </Grid>
        ) : (
          <Grid item xs={5}></Grid>
        )}

        {seeDetail && (
          <Grid item xs={2} md={2}>
            <Button
              name="buttonSeeDetails"
              variant="outlined"
              color="primary"
              className="Button-list-makita"
              size="small"
              onClick={() => {
                dispatch(setEnerbitServiceAgreement(enerbitServiceAgreement));
                onChangeModal
                  ? onChangeModal()
                  : navigate(`/detail/${enerbitServiceAgreement.id}`);
              }}
              sx={{ width: "65%", height: "2.5rem" }}
            >
              <Typography
                fontWeight="700"
                margin="0"
                color={enerbitColors.primary.main}
              >
                Ver detalle
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
