import {
    AutorenewIcon,
    BoltIcon,
    Box,
    Button,
    CircularProgress,
    Grid,
    InfoIcon,
    Pagination,
    Tooltip,
    TooltipProps,
    Typography,
    enerbitColors,
    styled,
    tooltipClasses,
} from "@enerbit/base";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as singleSpa from "single-spa";
import { StateStorage } from "../../models/StateStorage";
import { getListPlans } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { handlePageChangeListPlans } from "../../store/slices/calculator";
import { AppDispatch } from "../../store/store";

export type ModalChangePlanProps = {
    openModal: () => void;
};

const ServicesPlan = ({ openModal }: ModalChangePlanProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        isLoadingListPlans,
        paginationMarkets,
        listPlans,
        pageListPlans,
        countListPlans,
        totalListPlans,
    } = useSelector((state: StateStorage) => state.calculatorState);

    useEffect(() => {
        dispatch(getListPlans());
    }, []);

    if (isLoadingListPlans) {
        return (
            <Box className="Loading-flex">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                className="Title-service-plan"
                sx={{ margin: "0 0 1.375rem 0" }}
            >
                <Typography
                    color={enerbitColors.primary.main}
                    fontSize={"28px"}
                >
                    Servicios en este plan
                </Typography>
            </Box>

            <Box
                className="Container-services-plan-main"
                sx={{
                    color: enerbitColors.primary.main,
                    background: enerbitColors.neutral[100],
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography fontSize={"16px"}>
                            N° Servicios con este plan
                        </Typography>
                        <Typography fontSize={"24px"} fontWeight={700}>
                            {totalListPlans}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={openModal}
                            variant="contained"
                            color="error"
                        >
                            <AutorenewIcon />
                            Migrar a otro plan
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                ml: "10px",
                            }}
                        >
                            <HtmlTooltip
                                title={
                                    <Box sx={{ display: "flex" }}>
                                        <Box>
                                            <InfoIcon color="primary" />
                                        </Box>
                                        <Box>
                                            <Typography
                                                color={
                                                    enerbitColors.neutral[900]
                                                }
                                                fontWeight={900}
                                            >
                                                Mediante esta opción, todos los
                                                servicios que estén actualmente
                                                con este plan, se pasaran a
                                                algún otro plan que se indique.
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            >
                                <InfoIcon color="primary" />
                            </HtmlTooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="Detail-plan-box-service">
                {listPlans &&
                    listPlans.map((value, index) => (
                        <Grid key={index} container spacing={2}>
                            <Grid item xs={9} sx={{ pb: "16px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        className="Box-services-plan"
                                        sx={{
                                            background:
                                                enerbitColors.warning.main,
                                        }}
                                    >
                                        <BoltIcon sx={{ color: "white" }} />
                                    </Box>
                                    <Box>
                                        <Typography
                                            color={"#4A4A4A"}
                                            fontWeight={700}
                                            fontSize={"16px"}
                                        >
                                            {
                                                value.service_agreement
                                                    .description
                                            }
                                        </Typography>
                                        <Typography color={"#929292"}>
                                            {value.creg_subscriber
                                                .market_sui_code +
                                                " " +
                                                paginationMarkets?.items.find(
                                                    (e) =>
                                                        e.sui_code ==
                                                        value.creg_subscriber
                                                            .market_sui_code,
                                                )?.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", justifyContent: "end" }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="Button-list-makita"
                                    size="small"
                                    onClick={() => {
                                        singleSpa.navigateToUrl(
                                            `/#/electricity-supply-service/attention/${value.id}`,
                                        );
                                    }}
                                    sx={{ width: "110px", height: "2.5rem" }}
                                >
                                    <Typography
                                        fontWeight="700"
                                        margin="0"
                                        color={enerbitColors.primary.main}
                                    >
                                        Ver Detalle
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                <Box className="Pagination-tables">
                    <Pagination
                        count={countListPlans}
                        shape="rounded"
                        page={pageListPlans + 1}
                        sx={{ margin: "0.3rem 0 1rem 0" }}
                        onChange={(
                            event: ChangeEvent<unknown>,
                            value: number,
                        ) => {
                            dispatch(handlePageChangeListPlans(value - 1));
                            dispatch(getListPlans());
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 488,
        fontSize: 12,
        borderRadius: 16,
        padding: 16,
        border: "1px solid #dadde9",
    },
}));

export default ServicesPlan;
