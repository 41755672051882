import { NavSidebar, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import SectionInformation from "../pages/SectionInformation";
import SectionDetail from "../pages/details/SectionDetail";
import { persistor, store } from "../store/store";

export default function Routers() {
    return (
        <HashRouter basename="/makita">
            <Routes>
                <Route
                    path="/"
                    element={
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <ThemeConfig>
                                    <PrivateRoute>
                                        <>
                                            <NavSidebar />
                                            <section className="Container-search-makita">
                                                <SectionInformation />
                                            </section>
                                        </>
                                    </PrivateRoute>
                                </ThemeConfig>
                            </PersistGate>
                        </Provider>
                    }
                />
                <Route
                    path="/detail/:id"
                    element={
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <ThemeConfig>
                                    <PrivateRoute>
                                        <>
                                            <NavSidebar />
                                            <section className="Container-root">
                                                <SectionDetail />
                                            </section>
                                        </>
                                    </PrivateRoute>
                                </ThemeConfig>
                            </PersistGate>
                        </Provider>
                    }
                />
            </Routes>
        </HashRouter>
    );
}
