import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISupplyFormState } from "../../models/ISupplyFormState";

const initialState: ISupplyFormState = {
    liable_team: "",
    supply_type_id: "",
    settlement_period_id: "",
    deadline: new Date().toISOString(),
    submission_date: new Date().toISOString(),
    observations: "",
    state_id: "",
    blob: "",
};

const supplyFormSlice = createSlice({
    name: "supplyForm",
    initialState,
    reducers: {
        updateFormValues: (_state, action: PayloadAction<ISupplyFormState>) => {
            return action.payload;
        },
        resetFormValues: () => {
            return initialState;
        },
    },
});

export const { updateFormValues, resetFormValues } = supplyFormSlice.actions;

export default supplyFormSlice.reducer;
