import {
    Box,
    Button,
    CircularProgress,
    Typography,
    UploadOutlinedIcon,
    enerbitColors,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../models/StateStorage";
import {
    getSettlementSuppliesPeriod,
    getSettlementSupplyStates,
    getSettlementSupplyTypes,
} from "../../store/actions/supplies/supplies";
import { setOpenLoadFilesModal } from "../../store/slices/supply.slice";
import { AppDispatch } from "../../store/store";
import { CustomizedAccordion } from "../components/accordion/CustomizedAccordion";
import { LoadFilesModal } from "./components/LoadFilesModal";
import { SnackBar } from "./components/SnackBar";

export const LoadSupplies = () => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        settlementSuppliesPeriods: settlementSuppliesPeriods,
        isLoadingSettlementSuppliesPeriods,
    } = useSelector((state: StateStorage) => state.supplyState);

    const handleClickOpen = () => {
        dispatch(setOpenLoadFilesModal(true));
    };

    useEffect(() => {
        dispatch(getSettlementSuppliesPeriod());
        dispatch(getSettlementSupplyTypes());
        dispatch(getSettlementSupplyStates());
    }, []);

    return (
        <Box>
            <Box className="main-content">
                <LoadFilesModal />
                <SnackBar />
                <Box className="content-title">
                    <Box display="flex" alignItems="center" gap="8px">
                        <Typography
                            fontSize={20}
                            display="inline"
                            color={enerbitColors.primary.main}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Cargue de insumos
                            </span>{" "}
                        </Typography>
                    </Box>
                    <Box className="content-title">
                        <Button
                            id="Button-upload-files"
                            name="buttonUploadFiles"
                            color="warning"
                            size="small"
                            className="Loading-button Button-upload-files"
                            onClick={() => {
                                handleClickOpen();
                            }}
                            sx={{ height: "3rem" }}
                        >
                            <Box className="Button-text-create-plan">
                                <Box>
                                    <Box className="Icon-plus-create-plan">
                                        <UploadOutlinedIcon className="Icon-item" />
                                    </Box>
                                </Box>
                                <Typography
                                    variant="button"
                                    fontWeight="700"
                                    textTransform="none"
                                >
                                    Subir archivos
                                </Typography>
                            </Box>
                        </Button>
                        {/* <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              color="primary"
              sx={{ width: "50%", height: "3rem" }}
            >
              <span style={{ fontWeight: "normal" }}>Filtro por mes</span>
            </Button> */}
                    </Box>
                </Box>
            </Box>

            {isLoadingSettlementSuppliesPeriods ? (
                <Box className="Loading-flex">
                    <CircularProgress />
                </Box>
            ) : (
                settlementSuppliesPeriods.map((period: any) => {
                    return (
                        <CustomizedAccordion
                            key={""}
                            period={period}
                            canCheck={false}
                        />
                    );
                })
            )}
        </Box>
    );
};
