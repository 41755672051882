import React, { ErrorInfo } from "react";
import ReactDOM from "react-dom";
import { AppProps } from "single-spa";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    renderType: "createRoot",
    errorBoundary(err: Error, errInfo: ErrorInfo, props: AppProps) {
        return (
            <>
                Error name: {err.name}
                Message error: {err.message}
            </>
        );
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
