import {
  Interval,
  IntervalWithEnd,
} from "../models/plan-administration/PlanAdministration";
import { getTotalHoursInterval } from "./checkTimeIntervals";
import { ServiceAgreementType } from "./HelpersPlanAdministration";

/**
 * Genera el siguiente intervalo basado en el último intervalo de una lista de intervalos.
 * La hora de inicio del nuevo intervalo es la hora de fin del último intervalo.
 * La hora de fin del nuevo intervalo es una hora después de la nueva hora de inicio.
 * @param intervals - Un array de intervalos con hora de inicio y fin.
 * @returns Un nuevo intervalo donde la hora de inicio es la hora de fin del último intervalo y la hora de fin es una hora después de la nueva hora de inicio.
 */
export const generateNextInterval = (
  intervals: IntervalWithEnd[]
): IntervalWithEnd => {
  const lastInterval = intervals[intervals.length - 1];
  const lastEndHour = lastInterval
    ? parseInt(lastInterval.end.split(":")[0])
    : 0;

  const newStartHour = lastEndHour % 24;
  const newEndHour = (newStartHour + 1) % 24;

  const formattedEndHour = newEndHour === 0 ? 24 : newEndHour;

  return {
    start: `${newStartHour.toString().padStart(2, "0")}:00:00`,
    end: `${formattedEndHour.toString().padStart(2, "0")}:00:00`,
    duration: `${getTotalHoursInterval([lastInterval])}H`,
    price: 0,
  };
};

/**
 * Obtiene los intervalos iniciales basados en el tipo de acuerdo de servicio.
 * @param serviceAgreementType - El tipo de acuerdo de servicio.
 * @param intervals - Un array de intervalos con hora de inicio y fin.
 * @returns Un array de intervalos iniciales basado en el tipo de acuerdo de servicio.
 */
export const getInitialIntervals = (
  serviceAgreementType: ServiceAgreementType,
  intervals: IntervalWithEnd[]
): IntervalWithEnd[] => {
  if (serviceAgreementType === ServiceAgreementType.fijabit) {
    return [
      {
        start: "00:00:00",
        end: "23:00:00",
        duration: "24h",
        price: 0,
      },
    ];
  } else {
    return intervals;
  }
};

/**
 * Convierte una duración en horas a un formato de tiempo.
 * @param duration - La duración en horas (por ejemplo, "2H").
 * @returns La duración formateada como una cadena de tiempo (por ejemplo, "02:00:00").
 */
export const convertDurationToTime = (duration: string): string => {
  const hours = parseInt(duration.replace("H", ""), 10);
  const formattedHours = hours.toString().padStart(2, "0");
  return `${formattedHours}:00:00`;
};

/**
 * Calcula la hora de fin basada en la hora de inicio y la duración.
 * @param start - La hora de inicio en formato de cadena (por ejemplo, "02:00:00").
 * @param duration - La duración en horas en formato de cadena (por ejemplo, "2H").
 * @returns La hora de fin calculada en formato de cadena (por ejemplo, "04:00:00").
 */
export const calculateEndTimeFromDuration = ({
  start,
  duration,
}: {
  start: string;
  duration: string;
}): string => {
  const [startHours, startMinutes, startSeconds] = start.split(":").map(Number);

  const durationHours = parseInt(duration.replace("H", ""), 10);

  let totalHours = startHours + durationHours;

  if (totalHours === 24) {
    return "24:00:00";
  }

  const formattedHours = (totalHours % 24).toString().padStart(2, "0");
  const formattedMinutes = startMinutes.toString().padStart(2, "0");
  const formattedSeconds = startSeconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Valida si el número de intervalos es menor o igual a uno.
 * @param intervals - Un array de intervalos.
 * @returns Un booleano que indica si el número de intervalos es menor o igual a uno.
 */
export const validateIntervalsMinimum = (intervals: Interval[]): boolean => {
  return intervals.length <= 1;
};
