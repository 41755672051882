import { Box, CircularProgress, Pagination } from "@enerbit/base";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyServiceAgreements } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { handlePageChange } from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import { CardEnerbitServiceAgreement } from "./CardEnerbitServiceAgreement";

const ListPlansMakita = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoadingListPlansEnergy, planList, page } = useSelector(
        (state: StateStorage) => state.makitaState,
    );

    useEffect(() => {
        dispatch(getEnerbitElectricitySupplyServiceAgreements({}));
    }, []);

    return (
        <>
            <Box className="Container-plans-grid">
                {isLoadingListPlansEnergy ? (
                    <Box className="Loading-flex">
                        <CircularProgress />
                    </Box>
                ) : (
                    planList &&
                    planList.items.map((planEnergy, index) => {
                        return (
                            <Box key={index}>
                                <CardEnerbitServiceAgreement
                                    isChangePlan={false}
                                    seeDetail={true}
                                    key={planEnergy.id}
                                    enerbitServiceAgreement={planEnergy}
                                />
                            </Box>
                        );
                    })
                )}
                <Box
                    className="Pagination-tables"
                    padding="1rem 0"
                    marginBottom="0rem"
                    display="flex"
                >
                    <Pagination
                        className="Container-pagination-tables"
                        count={planList?.pages}
                        shape="rounded"
                        page={page + 1}
                        sx={{ margin: "0.3rem 0 1rem 0" }}
                        onChange={(
                            event: ChangeEvent<unknown>,
                            value: number,
                        ) => {
                            dispatch(handlePageChange(value - 1));
                            dispatch(
                                getEnerbitElectricitySupplyServiceAgreements({
                                    page: value - 1,
                                }),
                            );
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ListPlansMakita;
