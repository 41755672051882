import { formatterPeso } from "@enerbit/base";
import {
  Charge,
  ChargeConditions,
} from "../models/plan-administration/PlanAdministration";
import { formatPriceToCOPWithoutDecimals } from "../utils/formatPrice";

//TODO: Esta funcion tiene que ir para el base

/**
 * Calcula el valor del IVA basado en un valor sin IVA y un porcentaje de IVA.
 * @param valueWithoutIva - El valor sin IVA.
 * @param ivaPercentage - El porcentaje de IVA a aplicar (por defecto es 19).
 * @returns El valor del IVA calculado.
 */
export const calculateValueIva = (
  valueWithoutIva: number,
  ivaPercentage: number = 19
): number => {
  const valueWithIva = valueWithoutIva * (ivaPercentage / 100);
  return valueWithIva;
};

/**
 * Formatea el valor del IVA como una cadena en formato de moneda colombiana (COP) sin decimales.
 * @param valueWithoutIva - El valor sin IVA.
 * @returns La cadena formateada en formato de moneda colombiana sin decimales.
 */
export const calculateValueIvaTable = (valueWithoutIva: number): string =>
  formatPriceToCOPWithoutDecimals.format(calculateValueIva(valueWithoutIva));

/**
 * Calcula el valor total de una fila basado en un valor sin IVA.
 * @param valueWithoutIva - El valor sin IVA.
 * @returns El valor total de la fila calculado.
 */
export const calculateValueTotalRow = (valueWithoutIva: number): number => {
  const valueTotalRow = calculateValueIva(valueWithoutIva);
  return valueTotalRow;
};

/**
 * Calcula el total del valor para el cargo mensual fijo.
 * @param charges - Un array de cargos con sus condiciones.
 * @returns La cadena formateada en formato de moneda colombiana con el total del valor del cargo mensual fijo.
 */
export const calculateFixedMonthlyChargeValue = (
  charges: Charge<ChargeConditions>[]
): string => {
  let totalValueFixedMonthlyCharge = 0;

  charges.forEach((charge) => {
    if (charge.conditions.price !== undefined) {
      totalValueFixedMonthlyCharge += charge.conditions.price;
    }
  });

  return formatterPeso.format(totalValueFixedMonthlyCharge);
};

/**
 * Calcula el total del IVA del cargo mensual fijo.
 * @param charges - Un array de cargos con sus condiciones.
 * @returns La cadena formateada en formato de moneda colombiana con el total del IVA del cargo mensual fijo.
 */
export const calculateIvaFromColumnValue = (
  charges: Charge<ChargeConditions>[]
): string => {
  let totalIvaFixedMonthlyCharge = 0;

  charges.forEach((charge) => {
    if (charge.conditions) {
      if (charge.conditions.price !== undefined) {
        const valueWithIva = calculateValueIva(charge.conditions.price);
        totalIvaFixedMonthlyCharge += valueWithIva;
      }
    }
  });
  return formatPriceToCOPWithoutDecimals.format(totalIvaFixedMonthlyCharge);
};

/**
 * Calcula el total del cargo mensual fijo.
 * @param charges - Un array de cargos con sus condiciones.
 * @returns La cadena formateada en formato de moneda colombiana con el total del cargo mensual fijo.
 */
export const calculateTotalMonthlyCharge = (
  charges: Charge<ChargeConditions>[]
): string => {
  let totalMonthlyFixedCharge = 0;

  charges.forEach((charge) => {
    if (charge.conditions) {
      if (charge.conditions.price !== undefined) {
        const originalPrice = charge.conditions.price;
        const priceWithIva = calculateValueIva(originalPrice);
        const totalPriceWithIva = originalPrice + priceWithIva;
        totalMonthlyFixedCharge += totalPriceWithIva;
      }
    }
  });

  return formatPriceToCOPWithoutDecimals.format(totalMonthlyFixedCharge);
};
