import { ServiceAgreementType } from "./HelpersPlanAdministration";

export const getLabelServiceAgreementType = (
  serviceAgreementType: ServiceAgreementType
) => {
  switch (serviceAgreementType) {
    case ServiceAgreementType.fijabit:
      return "Fija";

    case ServiceAgreementType.dinabit:
      return "Variable";
  }
};
